import { createSlice } from '@reduxjs/toolkit';
export const caller = createSlice({
    name: 'caller',
    initialState: { id : null, kind : null, userid : null },
    reducers: {
        set: (state, action) => {
            state.id = action.payload.id;
            state.kind = action.payload.kind;
            state.userid = action.payload.userid;
        },
        reset: (state, action) => {
            state.id = null;
            state.kind = null;
            state.userid = null;
        }
    }
});
export const { set, reset } = caller.actions;
export default caller.reducer;