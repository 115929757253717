import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class chat_header  extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.User = ko.mapping.fromJS(Store.getState().profile.profile);
        this.unsubscribe = Store.subscribe(()=>{
            const profile = Store.getState().profile.profile;
            this.User.name(profile.name);
            this.User.surname(profile.surname);
            this.User.avatar(profile.avatar);
            this.User.about_me(profile.about_me);

            if (Store.getState().handler.name === 'notify' && !this.isExist(Store.getState().handler.params.id)) {
                this.addObservableNotify(Store.getState().handler.params.data);
            }
        });

        this.notifyItems = ko.observableArray([]);

        let elm = document.querySelectorAll('.js-toggle-chat-search');
        if(elm){
            elm.forEach(item => {
                item.addEventListener('click', (e)=>{
                    e.preventDefault();
                    document.getElementById('tynChatSearch').classList.toggle('active');
                });
            });
        }



    }

    goToPage(page) {
        document.getElementById('tynMain').classList.remove('main-shown');
        return page === 'home' ? this.Router.navigate('/') : this.Router.navigate('/contacts');
    }

    parseDescriptionText(text) {
        return (!!text && text.length > 25) ? text.substring(0, 25) + '...' : text;
    }

    getListNotify() {
        this.Server.Request('get_notify').then((res)=> {
            const response = JSON.parse(res);
            if (response.success) {
                response.notifications.forEach(notify => {
                    if (!this.isExist(notify.id))  this.addObservableNotify(notify);
                });
            }
        }).catch(e=>console.log(e));
    }
    updateNotify(res) {
        ko.utils.arrayMap(this.notifyItems(), (message) => {
            if (message.id() === res.id) {
                message.extradata_id(res.extradata_id);
            }
            return res;
        });
    }
    addObservableNotify(res) {
        const { recipient_id, id, extradata_id, status, authid,updatedAt,type } = res;
        if (this.isExist(res.id)) {
            this.updateNotify(res);
            return false;
        }

        let notifyItem = ko.mapping.fromJS({
            authid,
            updatedAt,
            id,
            recipient_id,
            extradata_id,
            status,
            type,
            avatar: '',
            name: '',
            surname: '',
        });

        this.notifyItems.push(notifyItem);

        this.Server.Subscribe('user_profile', res.recipient_id).then(Subscribe => {
            this.updateField(notifyItem, Subscribe.get(), 'name', 'surname', 'avatar');
            Subscribe.on('update', (newVal) => {
                this.updateField(notifyItem, newVal, 'name', 'surname', 'avatar');
            });
        });
    }
    updateField(dialog, newVal, ...fieldsName) {
        fieldsName.forEach(field => {
            if (newVal.hasOwnProperty(field)) {
                dialog[field](newVal[field]);
            }
        });
    }

    isExist(id) {
        return ko.utils.arrayFilter(this.notifyItems(), (item) => {
            if (item.id() === id) return id;
        }).length >= 1;
    }

    changeDarkMode(type) {
        this.Server.Request('add_dark_mode_device', {dark_mode:type}).then(()=>{
            this.Store.dispatch({type: 'dark_mode/set', payload: type});
        }).catch(e=>console.log(e));
        return true;
    }

    getDateFormat(item) {
        const date = new Date(item);
        let dayOfMonth = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let hour = date.getHours();
        let minutes = date.getMinutes();
        let diffMs = new Date() - date;
        let diffSec = Math.round(diffMs / 1000);
        let diffMin = Math.round(diffSec / 60);
        let diffHour = Math.round(diffMin / 60);
        let diffDays = Math.round(diffHour / 24);

        // форматирование
        month = month < 10 ? '0' + month : month;
        dayOfMonth = dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth;
        hour = hour < 10 ? '0' + hour : hour;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        if (diffSec < 1) {
            return this.i18next.t('just now');
        } else if (diffMin < 1) {
            return diffSec + ' ' + this.i18next.t('sec. ago');
        } else if (diffHour < 1) {
            return diffMin + ' ' + this.i18next.t('min. ago');
        } else if (diffDays < 1) {
            return diffHour + ' ' + this.i18next.t('h. ago');
        } else if (diffDays < 2) {
            return this.i18next.t('yesterday') + ' ' + hour + ':' + minutes;
        } else {
            return dayOfMonth + '.' + month + '.' + year + ' ' + hour + ':' + minutes;
        }
    }

    logOut() {
        this.Server.Request('remove_device')
            .then(res=>{
                let result = JSON.parse(res);
                if (result.success) {
                    this.Store.dispatch({ type: 'auth/set', payload:false });
                    this.Router.navigate('/login');
                } else {
                    console.log(result.error);
                }
            }).catch(error=> console.log(error));

    }
    /*chooseSearchMessage(data) {
        this.Store.dispatch({type: 'handler/set', payload: {name: 'search_message',params: {id: data.id(), data: {recipient_id: data.recipient_id(), extradata_id: data.extradata_id()}}}});
        this.Store.dispatch({type: 'open_dialogue/set', payload: 0});
        this.Router.navigate('/dialog/' + data.recipient_id());
        this.Router.setLocation('/dialog/' + data.recipient_id());

        // this.notifyItems.remove(notify => notify.id() === data.id())
    }*/

    chooseSearchMessage(data) {
        this.Router.navigate('/dialog/' + data.recipient_id());
    }


}
