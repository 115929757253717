import ko from 'knockout';
ko.bindingHandlers.resize = {
    init: function (element, valueAccessor, allBindings) {
        "use strict";

        (new IntersectionObserver(function (entries){
            for (let i = 0; i < entries.length; i++) {
                if(entries[i].isIntersecting){
                    entries[i].target.setAttribute("style", "height:" + (entries[i].target.scrollHeight) + "px;overflow-y:hidden;");
                }
            }
        })).observe(element);

        element.oninput = function (e){
            e.target.style.height = 0;e.target.style.height = (e.target.scrollHeight) + 'px';
        };

    }
};
