import { createSlice } from "@reduxjs/toolkit";

export const dialogHandler  = createSlice({
    name: 'dialog',
    initialState: {
        dialog: {}
    },
    reducers: {
        set: (state, action) => {
            state.dialog = action.payload;
        }
    }
});

export const { set } = dialogHandler.actions;
export default dialogHandler.reducer;