import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";

export default class home extends Handler {
    constructor({Store, Router, Server, i18next, Request}) {
        super({Store, Router, Server, i18next});
        this.dialogsList = ko.observableArray([]);
        this.searchDialogsList = ko.observableArray([]);
        this.DialogAuthid = ko.observable(0);
        this.searchChat = ko.observable('');
        this.searchTab = ko.observable(false);
        this.currentNickname = ko.observable(Request.params.nickname);
        this.blacklistChatId = ko.observable(null);
        this.blacklistUserId = ko.observable(null);
        this.type = ko.observable('active');
        this.all_dialogs_length = ko.observable(0);
        this.offset = ko.observable(0);
        this.isDialogSelected = ko.observable(false);
        this.contactIdsList = ko.observableArray([]);

        this.unsubscribe = Store.subscribe(() => {
            if (Object.keys(Store.getState().dialogHandler.dialog).length !== 0 && !this.isExist(Store.getState().dialogHandler.dialog)) {
                this.addObservableDialog(Store.getState().dialogHandler.dialog);
            }
            if (Store.getState().handler.name === 'dialog' && !this.isExist(Store.getState().handler.params.id) ) {
                this.Server.Subscribe('dialogs', Store.getState().handler.params.id).then(Subscribe => {
                    this.addObservableDialog(Subscribe.get());
                });
            } else if (Store.getState().handler.name === 'dialog' && this.isExist(Store.getState().handler.params.id)) {
                ko.utils.arrayMap(this.dialogsList(), (contact) => {
                    if (contact.id() === Store.getState().handler.params.id) {
                        contact.last_message(Store.getState().handler.params.data.last_message);
                        contact.updatedAt(Store.getState().handler.params.data.updatedAt);
                        contact.count(Store.getState().handler.params.data.count);
                    }
                    return contact;
                });
            }
        });

        if (Store.getState().handler.name === 'telegramAuth') {
            window.location.href = '/confirm_telegram?chatId='+ Store.getState().handler.params.chatId + '&code='+ Store.getState().handler.params.code;
        }


        this.Store.dispatch({type: 'open_dialogue/set', payload: 0});

        if (!!Request.params.id) this.setDialogId(Request.params.id);

        this.limit = 20;

        this.getListData('get_my_dialogs_by_status', this.type());

        document.querySelector('.tyn-aside-body').addEventListener('scroll', (e) => {
            if (Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight && this.all_dialogs_length() > this.offset()) {
                this.offset(this.dialogsList().length);
                this.getListData('get_my_dialogs_by_status', this.type());
            }
        });

        this.type.subscribe((type) => {
            this.offset(0);
            this.getListData('get_my_dialogs_by_status', type);
            this.dialogsList([]);
        });

        this.getMyContacts();

    }

    isExist(id) {
        return ko.utils.arrayFilter(this.dialogsList(), (item) => {
            if (item.id() === id) return id;
        }).length >= 1;
    }

    getListData(route, status) {
        this.Server.Request(route, {limit: this.limit, offset: this.offset(), status: status})
            .then(res => {
                const response = JSON.parse(res);
                this.all_dialogs_length(response.count);

                response.dialogs.forEach((res) => {
                    if (response.success) {
                        this.addObservableDialog(res, 'dialogsList');
                    }
                });
            }).catch((e) => console.log(e));
    }


    addNewContact(contact_user_id) {
        this.Server.Request('add_contact', {contact_user_id})
            .then(() => {
                this.contactIdsList.push(contact_user_id);
            })
            .catch((e) => console.error(e));
    }

    isContact(speaker) {
        return ko.utils.arrayFilter(this.contactIdsList(), (item) => {
            if (item === speaker) return item;
        }).length >= 1;
    }

    getMyContacts() {
        this.Server.Request('all_my_contacts')
            .then(res => {
                const response = JSON.parse(res);
                this.contactIdsList(response.users);
            }).catch((e) => console.log(e));
    }

    setDialogId(id) {
        this.Router.setLocation('/dialog/' + id);

        if (Number(id) > 0 && Number(this.Store.getState().openDialogueHandler.id) !== Number(id)) {
            this.DialogAuthid(Number(id));
            this.Store.dispatch({type: 'open_dialogue/set', payload: Number(id)});
            //setTimeout(()=>{
            // },0);
        }
        document.getElementById('tynMain').classList.add('main-shown');

        this.isDialogSelected(true);
    }



    addObservableDialog(res) {
        const { last_message, userid, id, status, count, updatedAt, name, surname, nickname, authid, avatar, bg } = res;

        if (this.isExist(res.id)) return false;

        let dialogItem = ko.mapping.fromJS({
            last_message,
            userid,
            id,
            updatedAt,
            name,
            count,
            surname,
            nickname,
            authid,
            bg,
            avatar,
            status
        });

        this.dialogsList.push(dialogItem);

        this.Server.Subscribe('dialogs', res.id).then(Subscribe => {
            this.updateField(dialogItem, Subscribe.get(), 'last_message', 'updatedAt');
            Subscribe.on('update', (newVal) => {
                this.Store.dispatch({ type: 'message/setMessage', payload: newVal });
                this.updateField(dialogItem, newVal, 'last_message', 'updatedAt');
            });
        });

        this.Server.Subscribe('user_profile', res.userid).then(Subscribe => {
            this.updateField(dialogItem, Subscribe.get(), 'name', 'surname', 'nickname', 'authid', 'avatar', 'bg');
            Subscribe.on('update', (newVal) => {
                this.updateField(dialogItem, newVal, 'name', 'surname', 'nickname', 'authid', 'avatar', 'bg');
            });
        });
    }


    updateField(dialog, newVal, ...fieldsName) {
        fieldsName.forEach(field => {
            if (newVal.hasOwnProperty(field)) {
                dialog[field](newVal[field]);
            }
        });
    }
}
