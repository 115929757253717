import { bindingHandlers } from 'knockout';
bindingHandlers.audioStream = {
    init: function(e, v) {
        "use strict";
        if(v().muted()===false){
            e.onloadeddata = () => e.play() ;
        }
        e.onerror = (error) => console.log(error);
        e.autoplay = true;
        e.muted = v().muted();
        v().muted.subscribe(newValue=>{
            e.muted = newValue;
            if(newValue === false){
                e.play();
            }
        });
        e.controls = false;
        e.playsinline = true;
        e.style.opacity = 1;
        e.allowsInlineMediaPlayback = true;
        e.setAttribute('webkit-playsinline', true);
        e.setAttribute('playsinline', true);
        e.srcObject = v().stream;
        if(v().muted()===false){
            e.play().catch((err) => {  console.log("audioElem.play() failed:%o", err);  } );
        }

    }
};