import { createSlice } from '@reduxjs/toolkit';
export const dark_mode = createSlice({
    name: 'dark_mode',
    initialState: { value: false },
    reducers: {
        set: (state, action) => {
            state.value = action.payload;
        }
    }
});
export const { set } = dark_mode.actions;
export default dark_mode.reducer;