import { bindingHandlers } from 'knockout';
import { v4 as uuid } from 'uuid';
const bindImgByID  = function( element, valueAccessor,q, { Router, Server, Store, i18next }, context ) {
    let  bg = valueAccessor();
    element.setAttribute('hash', bg());
    if(Server === undefined) Server  = context.$component.Server;
    if(Server === undefined && context.$parentContext.$data.hasOwnProperty('Server')) Server  = context.$parentContext.$data.Server;
    let update = ()=>{ Server.fileByID( bg() ).then((file)=>{

        if(!element.id){
            //element.id = uuid();
            element.setAttribute('hash', bg());
            URL.revokeObjectURL(element.src);
            element.src = URL.createObjectURL(file.blob);
            element.alt = file.name;
        }
    }).catch((e)=>{element.alt = e })  }
    (bg())? update(): ()=>{};
    bg.subscribe(()=>update())
    return { controlsDescendantBindings: true };
}

bindingHandlers.imageByID = { init : bindImgByID, update : bindImgByID };