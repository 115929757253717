import { createSlice } from '@reduxjs/toolkit';
export const profile = createSlice({
    name: 'profile',
    initialState: {
        profile: {
            name: 'guest', surname: 'guest', address: '', country: '', city: '', birthday: null, nickname: '', lang: '', gender: '', bg: null, avatar: null, id: 0, about_me: ''
        },
        settings: {}
    },
    reducers: {
        setProfile: (state, action) => {
            state.profile = {...state.profile, ...action.payload};
        },
        setProfileSettings: (state, action) => {}
    }
});
export const { set } = profile.actions;
export default profile.reducer;
