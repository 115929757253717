import { createSlice } from '@reduxjs/toolkit';
export const audio = createSlice({
    name: 'audio',
    initialState: { id: null, name:null, surname:null, bg:null, avatar:null, gender:null },
    reducers: {
        set: (state, action) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.surname = action.payload.surname;
            state.bg = action.payload.bg;
            state.avatar = action.payload.avatar;
            state.gender = action.payload.gender;
        }
    }
});
export const { set } = audio.actions;
export default audio.reducer;