import Store from './redux/store';
import { applyBindingsToNode, cleanNode } from 'knockout';
import 'Pages';
import 'Components';
import './src/assets/css/bundle.css';
import './src/assets/css/app.css';
import './src/assets/css/custom.css';
import {createRouter} from 'routerjs';
import 'binding/i18nText';

import  network  from 'engine/network';
import config from './src/config.json';
import i18next from 'i18next';
import langRu from './src/lang/ru.json';
import langEn from './src/lang/en.json';
// import RouterHandler from "engine/RouteHandlers";
const body = document.getElementsByTagName('body')[0];
const root = document.createElement('div');
root.className = 'page-wrapper';
root.id = 'root';
body.appendChild(root);
config.url = 'https://'+location.hostname+':975';
i18next.init({ lng: Store.getState().lang.value,debug: false,  resources: { ru: { translation : langRu }, en : { translation : langEn } }} ).then(()=>Store.subscribe(()=>i18next.changeLanguage(Store.getState().lang.value)));

Store.subscribe(()=>body.setAttribute("data-bs-theme", Store.getState().dark_mode.value));

const Server = new network(config.url);
Server.connect().then(async ()=> {

    Store.dispatch({ type: 'auth/set', payload:Server.User.auth });
    Store.dispatch({ type: 'dark_mode/set', payload:Server.User.dark_mode });
    Store.dispatch({ type: 'lang/set', payload:Server.User.lng });

    if(Server.User.auth){
        Server.Request('user_profile_info').then(resp=>{
           let profile   = JSON.parse(resp);
           Server.Subscribe('user_profile',profile.id).then(Subscribe=>{
               Subscribe.on('update',(NewValue)=>Store.dispatch({ type: 'profile/setProfile', payload:{...NewValue} }));
               Store.dispatch({ type: 'profile/setProfile', payload:{...Subscribe.get()} });
            });
            Server.Subscribe('handler',profile.id).then(Subscribe=>{
                Subscribe.on('update',(NewValue)=>{
                    setTimeout(() => {
                        Store.dispatch({type: 'handler/set', payload: {name: NewValue.type,params: {id: NewValue.extradata_id, data: NewValue.data}}});
                    },0);
                });
            });
        });
    }


    const Router = createRouter();

    const Page = (name,Request)=> applyBindingsToNode(root, { component: { name , params : { Store, Router, Server, i18next, Request } } });

    const AuthMiddleware = (name, isAuthCheck = true) => (Request) => {
        cleanNode(root);
        if (!Store.getState().auth.value && isAuthCheck) {
            return Server.Request('remove_device').then(() => Router.navigate('/login')).catch(error=> console.log(error));
        }
        return Page(name, Request);
    };
    // const AuthMiddlewareByRoute = () => (Request) => {
    //     cleanNode(root);
    //     console.log(Request);
    // };

    Router
        .get('/', AuthMiddleware('home'))
        .get('/home', AuthMiddleware('home'))
        .get('/dialog/:id', AuthMiddleware('home'))
        .get('/contacts', AuthMiddleware('contacts'))
        .get('/forgot',  AuthMiddleware('forgot',false))
        .get('/login',  AuthMiddleware('login',false))
        .get('/profile', AuthMiddleware('profile'))
        .get('/register',  AuthMiddleware('register',false))
        .get('/confirm_telegram',  AuthMiddleware('confirm_telegram',false))
        .get('/contacts/:nickname', AuthMiddleware('contacts'))
        .error(404, (/*err, context*/) => {
            Page('not_found');
        })
        .run();

    //старая версия
    // const Router = new RouterHandler();
    // const Page = (name)=>applyBindingsToNode(root, { component: { name , params : { Store, Router, Server, i18next } } });
    //
    // // Router.change = () => cleanNode(root);
    // Router
    //     .on('/', () => Page('home'))
    //     .on('/home', () => Page('home'))
    //     .on('/company_reg', () => Page('company_reg'))
    //     .on('/reg_account_choose', () => Page('reg_account_choose'))
    //     .on('/remember', () => Page('remember'))
    //     .on('/web_constructor', () => Page('web_constructor'))
    //     .on('/web_constructors', () => Page('web_constructors'))
    //     .on('/login', () => Page('login'))
    //     .on('/user_reg', () => Page('user_reg'))
    //     .notFound(() =>{throw Error('Page not found');})
    //     .init();
 });


