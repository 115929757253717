import { createSlice } from "@reduxjs/toolkit";

export const handler = createSlice({
    name: 'handler',
    initialState: {
        name: 'initial',
        params: {}
    },
    reducers: {
        set: (state, action) => {
            state.name = action.payload.name;
            state.params = action.payload.params;
        }
    }
    // this.Store.dispatch({type: 'handler/set', payload: {name: 'delete_message', params: {id, data: {id, recipient_id, sender_id}}}});
});

export const { set } = handler.actions;
export default handler.reducer;
