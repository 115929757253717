import { createSlice } from "@reduxjs/toolkit";

export const open_dialogue = createSlice({
    name: 'open_dialogue',
    initialState: { id: 0 },
    reducers: {
        set: (state, action) => {
            state.id = action.payload;
        }
    }
});

export const { set } = open_dialogue.actions;
export default open_dialogue.reducer;
