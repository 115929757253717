//import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import ko from 'knockout';

export default class forgot extends Handler {
    constructor({Store, Router, Server, i18next}) {
        super({Store, Router, Server, i18next});

        if (Store.getState().auth.value) {
            Router.navigate('/');
        }

        this.numberBlock = ko.observable('email');
        this.showPassword = ko.observable(false);
        this.showPasswordRepeat = ko.observable(false);
        this.checkMail = ko.observable(false);
        this.errorAdd = ko.observable('');

        this.name = ko.observable('');
        this.email = ko.observable('');
        this.surname = ko.observable('');

        this.errorEmail = ko.observable('');

        this.mail_code = ko.observable('');
        this.password = ko.observable('');
        this.password_repeat = ko.observable('');

        this.passwordEmpty = ko.observable(false);
        this.passwordLength = ko.observable(true);
        this.passwordLat = ko.observable(true);
        this.passwordNumber = ko.observable(true);
        this.passwordSymbol = ko.observable(true);
        this.successfulEmailCode = ko.observable(false);

        this.showsecMail = ko.observable(0);
        this.stopsend = ko.observable(0);

        this.passwordRequired = ko.computed(function () {
            this.passwordEmpty(this.str_rot13() !== '');
            this.passwordLength(this.str_rot13().length < 8);
            this.passwordLat(!!(!/^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*[a-zA-Z]+[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/.test(this.str_rot13())));
            this.passwordNumber(!!(!/\d/.test(this.str_rot13())));
            this.passwordSymbol(!!(!/[!@#$%^&*(),.?":{}|<>]/.test(this.str_rot13())));

            return !(this.passwordEmpty() === true && this.passwordLength() === false && this.passwordLat() === false && this.passwordNumber() === false && this.passwordSymbol() === false);
        }, this);
    }

    showEmailStep() {
        this.numberBlock('email');
        this.changeStateFunction('email');
    }

    showCheckEmailStep() {
        this.numberBlock('check_email');
    }
    showLastStep() {
        this.numberBlock('last');
        this.changeStateFunction('last');
    }

    changeState (param) {
        let activeItem = document.querySelector(".steps_block .active");
        if (activeItem) {
            let nextItem = param === 'prev' ? activeItem.previousElementSibling : document.querySelector("." + param);
            if (nextItem) {
                activeItem.classList.remove("active");
                nextItem.classList.add("active");
                switch (true) {
                    case nextItem.classList.contains("step_email"):
                        this.showEmailStep();
                        break;
                    case nextItem.classList.contains("step_check_email"):
                        this.showCheckEmailStep();
                        break;
                    case nextItem.classList.contains("steps_last"):
                        this.showLastStep();
                        break;
                    default:
                        this.showPhoneStep();
                        break;
                }
            }
        }
    }

    changeStateFunction(number) {
        switch (number) {
            case "email":
                return !!this.email() && this.validateEmail();
            case "default":
                return !!this.truePhone() && this.validatePhone() && !!this.email() && this.validateEmail() && !!this.checkSMS() && !!this.checkMail();
            case "last":
                return !this.passwordRequired() && !!this.password() && this.password() === this.password_repeat();
        }
    }


    validateName() {
        const nameRegex = /^[a-zA-Zа-яА-Я]+(?:-[a-zA-Zа-яА-Я]+)?(?: [a-zA-Zа-яА-Я]+(?:-[a-zA-Zа-яА-Я]+)?)?$/;
        return this.name().length >= 3 && nameRegex.test(this.name());
    }

    validateEmail() {
        return /@[^.]+\.\w/.test(this.email());
    }

    str_rot13() {
        return this.password().replace(/[a-zA-Z]/g, (c) => {
            return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
        });
    }

    checkPasswordRepeat() {
        return this.password() && this.password() === this.password_repeat();
    }

    EventPassword() {
        return {
            keyup: function (d, event) {
                this.password(event.currentTarget.value);
            }
        };
    }


    mailCountdown(stopsec) {
        let seconds = Math.floor(new Date().getTime() / 1000);
        let sendEmail = document.getElementById("sendEmail");
        let waitSendEmail = document.getElementById("waitSendEmail");
        let waitSecEmail = document.getElementById("waitSecEmail");
        this.showsecMail(stopsec - seconds);
        if ((this.showsecMail() <= 0 || this.stopsend() === 1) && (sendEmail !== null && waitSendEmail !== null)) {
            sendEmail.style.display = 'block';
            waitSendEmail.style.display = 'none';
            return;
        }
        if (waitSecEmail !== null) {
            waitSecEmail.innerHTML = this.showsecMail();
        }
        const _self = this;
        setTimeout(function () {
            _self.mailCountdown.call(this, stopsec);
        }.bind(_self), 1000);
    }

    sendEmailRequest() {
        this.Server
            .Request('add_reset_email', {email: this.email()})
            .then(res => {
                let result = JSON.parse(res);

                if (result.success) {
                    let sendEmail = document.getElementById("sendEmail");
                    let waitSendEmail = document.getElementById("waitSendEmail");

                    if (sendEmail !== null && waitSendEmail !== null) {
                        sendEmail.style.display = 'none';
                        waitSendEmail.style.display = 'block';
                    }
                    this.errorEmail('');
                    this.stopsend(0);
                    this.showsecMail(60);
                    const seconds = Math.floor(new Date().getTime() / 1000) + this.showsecMail();
                    this.mailCountdown.call(this, seconds);
                    this.changeState('step_check_email');
                    this.successfulEmailCode(true);
                }
            }).catch(error => {
            this.errorEmail(error);
            return false;
        });
    }

    validateMail() {
        if (this.mail_code() === '') {
            this.errorEmail(this.i18next.t('code cannot be empty'));
            return false;
        }
        this.Server
            .Request('check_reset_email', {email: this.email(), code: this.mail_code()})
            .then(res => {
                res = JSON.parse(res);
                if (res.success) {
                    this.checkMail(true);
                    this.errorEmail('');
                    this.changeState('steps_last');
                    // this.successfulEmailCode(true);
                } else {
                    this.errorEmail(this.i18next.t(res));
                }
            }).catch(error => {
            this.errorEmail(this.i18next.t(error));
            this.checkMail(false);
        });
    }


    sendForm() {
        this.Server
            .Request('change_pass', {
                password: this.password(),
                email: this.email(),
                mail_code: this.mail_code()
            })
            .then((res) => {
                let result = JSON.parse(res);
                if(result.success)  this.Router.navigate('/login');
            })
            .catch((error)=>{
                this.checkMail(false);
                this.errorEmail(this.i18next.t(error));
            });
    }

}
