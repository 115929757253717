//import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import ko from "knockout";
import * as countries from './../../assets/data/countries.json';
import 'binding/resize';
import Toastify from "toastify-js";
import intlTelInput from "intl-tel-input";
export default class profile  extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        this.countries = ko.observable(countries);
        this.interfacelang = ko.observable(Store.getState().lang.value);
        this.fullname = ko.observable('');
        this.fullnickname = ko.observable('');
        this.name = ko.observable(null);
        this.surname = ko.observable('');
        this.nickname = ko.observable(null);
        this.lang = ko.observable('');
        this.gender = ko.observable('');
        this.birthday = ko.observable('');
        this.country = ko.observable('');
        this.city = ko.observable('');
        this.bg = ko.observable(null);
        this.avatar = ko.observable(null);
        this.id = ko.observable(0);
        this.aboutMe = ko.observable('');
        this.inValidNickName = ko.observable(false);
        this.systemTheme = ko.observable(Store.getState().dark_mode.value);

        this.numberBlock = ko.observable('phone');
        this.checkSMS = ko.observable(false);
        this.checkMail = ko.observable(false);
        this.errorAdd = ko.observable('');
        this.phone = ko.observable('');
        this.email = ko.observable('');
        this.profileEmail = ko.observable('');
        this.truePhone = ko.observable('');
        this.errorSMS = ko.observable('');
        this.errorEmail = ko.observable('');
        this.errorPhone = ko.observable('');
        this.sms = ko.observable('');
        this.mail_code = ko.observable('');
        this.validatePhone = ko.observable(false);
        this.successfulEmailCode = ko.observable(false);
        this.showsecSMS = ko.observable(0);
        this.showsecMail = ko.observable(0);
        this.stopsend = ko.observable(0);
        this.smsRevText = ko.observable(true);
        this.isShowName = ko.observable(true);
        this.isShowSurName = ko.observable(true);
        this.isShowNumber = ko.observable(true);
        this.isShowBirth = ko.observable(true);
        this.isShowAddress = ko.observable(true);
        this.isShowMeInSearches = ko.observable(true);

        this.loadBg = ko.observable(0);
        this.loadAvatar = ko.observable(0);
        this.unsubscribe = Store.subscribe(()=>this.updateProfile());
        this.updateProfile();
        this.updateSettings();
        this.countContacts = ko.observable(0);
        this.contactList = ko.observableArray([]);
        this.getContacts();

        const inputPhone = document.querySelector('#phone');

        const iti = intlTelInput(inputPhone, {
            preferredCountries: ['ru', 'us'],
            autoPlaceholder: 'polite',
            nationalMode: false,
            initialCountry: 'RU',
            placeholderNumberType: 'MOBILE',
            utilsScript:
                'js/utils.js',
        });

        inputPhone.addEventListener('input', () => {
            if (inputPhone.value.replace(/\D/g, '').length > 5) {
                this.validatePhone(!!iti.isValidNumber());
            } else {
                this.validatePhone(false);
            }
        });

        inputPhone.addEventListener('keyup', () => {
            if (inputPhone.value.replace(/\D/g, '').length > 5) {
                this.validatePhone(!!iti.isValidNumber());
            } else {
                this.validatePhone(false);
            }
        });

        inputPhone.addEventListener('keyup', () => {
            if (iti.isValidNumber()) {
                this.truePhone(window.intlTelInputGlobals.getInstance(inputPhone).getNumber());
                this.validatePhone(true);
            } else {
                this.truePhone('');
                this.validatePhone(false);
            }
        });
    }
    getListData(listType, route) {
        this.Server.Request(route, {limit: 5, offset: 0})
            .then(res => {
                const response = JSON.parse(res);
                response.users.forEach((id) => {
                    this.Server.Subscribe('user_profile', id).then(Subscribe => {
                        let user = Subscribe.get();
                        this[listType].push(ko.mapping.fromJS(user));
                    });
                });
                this.countContacts(response.count);
            }).catch((e) => console.log(e));
    }
    getContacts() {
        this.getListData('contactList', 'my_contacts');
    }
    async sendBg(model, { target }) {
        this.Server.sendFiles( target.files,1000)
            .then(Files=>Files.forEach(file=>{
                if( file.get('object_id') && Math.round( file.get('offset')/(file.get('size')/100) )===100){
                    this.bg(file.get('object_id'));
                    this.loadBg(0);
                }
                file.on('update',(newValue)=>{
                    if( Math.round( newValue.offset/(newValue.size/100) ) === 100){
                        this.loadBg(0);
                        if(newValue.object_id) this.bg(newValue.object_id);
                    }else this.loadBg( Math.round( newValue.offset/(newValue.size/100) ));
                });
            }));
    }

    async sendAvatar(model, { target }) {
        this.Server.sendFiles( target.files,1000)
            .then(Files=>Files.forEach(file=>{
                if( file.get('object_id') && Math.round( file.get('offset')/(file.get('size')/100) )===100){
                    this.avatar(file.get('object_id'));
                    this.loadAvatar(0);
                }
                file.on('update',()=>{
                    if( Math.round( file.get('offset')/(file.get('size')/100) ) === 100){
                        this.loadAvatar(0);
                        if(file.get('object_id')) this.avatar(file.get('object_id'));
                    }else this.loadAvatar( Math.round( file.get('offset')/(file.get('size')/100) ));
                });
            }));
    }

    updateProfile() {
        const profile = this.Store.getState().profile.profile;
        if (profile.address) {
            this.country(profile.address.split(',')[0]);
            this.city(profile.address.split(',')[1]);
        }
        if (profile.birthday !== null) {
            this.birthday(this.parseDateToInput(new Date(Date.parse(profile.birthday))));
        }
        this.name(profile.name);
        this.surname(profile.surname);
        this.nickname(profile.nickname);
        this.lang(profile.lang);
        this.gender(profile.gender);
        this.id(profile.id);
        this.fullname(profile.surname + ' ' + profile.name);
        this.fullnickname(profile.nickname);
        this.aboutMe(profile.about_me);
        this.bg(profile.bg);
        this.avatar(profile.avatar);

        if (profile.id > 0) {
            this.Server.Subscribe('email', profile.id).then(Subscribe => {
                this.profileEmail(Subscribe.get().email);
                Subscribe.on('update', (newVal) => {
                    this.profileEmail(newVal.email);
                });
            });
        }
    }

    updateSettings() {
        this.Server.Request('get_profile_settings')
            .then(resp => {
                resp = JSON.parse(resp);
                if (resp.success) {
                    this.isShowName(resp.settings.show_name);
                    this.isShowSurName(resp.settings.show_surname);
                    this.isShowNumber(resp.settings.show_number);
                    this.isShowBirth(resp.settings.show_birthday);
                    this.isShowAddress(resp.settings.show_address);
                    this.isShowMeInSearches(resp.settings.show_me_in_search);
                }
            });
    }

    saveSettings() {
        const settings = {
            name: this.name(),
            surname: this.surname(),
            show_name: this.isShowName(),
            show_surname: this.isShowSurName(),
            show_number: this.isShowNumber(),
            show_birthday: this.isShowBirth(),
            show_address: this.isShowAddress(),
            show_me_in_search: this.isShowMeInSearches()
        };

        this.Server.Request('save_profile_settings', settings)
            .then(result => {
                result = JSON.parse(result);
                Toastify({
                    text: this.i18next.t(result.message),
                    duration: 3000,
                    gravity: "top",
                    position: "right",
                    style: {
                        background: "success"
                    },
                    close: true,
                }).showToast();
            });
    }

    parseDateToInput(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toJSON().slice(0,10);
    }

    eventField(fieldName) {
        return {
            input: (data, event) => {
                if (fieldName === 'name' || fieldName === 'surname') {
                    event.target.value = event.target.value.replace(/[^a-zA-ZА-Яа-яЁё]/g,'');
                } else {
                    event.target.value = event.target.value.replace(/[^a-zA-Z0-9_]/g,'');
                }
            }
        };
    }

    validateNickname() {
        if (this.nickname().length > 0) {
            this.debounce(500, () => {
                this.Server
                    .Request('check_free_nickname', {nickname: this.nickname()})
                    .then(res => {
                        res = JSON.parse(res);

                        if (res.success || !res.success && res.authid === this.id()) {
                            return this.inValidNickName(false);
                        }
                        return this.inValidNickName(true);

                    }).catch(error => {
                    console.log(error);
                });
            });
        } else {
            this.inValidNickName(false);
            return true;
        }

        return this.nickname().length >= 3;
    }

    validateName() {
        if (this.name() === null) return true;
        return this.name().length >= 3;
    }

    saveProfileForm() {
        let address = '';
        if (this.country() !== '') address += this.country().trim();
        if (this.country() !== '' && this.city() !== '') address += ', ';
        if (this.city() !== '') address += this.city();

        const requestData = {
            name: this.name().trim(),
            surname: this.surname().trim(),
            nickname: this.nickname().trim(),
            birthday: this.birthday() === '' ? null : this.birthday(),
            gender: this.gender() === '' ? null : this.gender(),
            lang: this.lang() === '' ? null : this.lang(),
            id: this.id(), address,
            about_me: this.aboutMe(),
            bg: this.bg(),
            avatar: this.avatar(),
        };

        this.Server.Request('update_profile', requestData)
            .then(result => {
                result = JSON.parse(result);
                if (result.success) {
                    this.Store.dispatch({ type: 'profile/setProfile', payload: requestData});
                    this.fullname(this.surname().trim() + ' ' + this.name().trim());
                    this.fullnickname(this.nickname().trim());

                    this.Server.Request('add_nickname', {nickname: this.nickname().trim()}).catch(e => console.log(e));

                    this.showToast(this.i18next.t('Profile changes successfully saved'), 'success')
                }
                console.log(this.i18next.t(result.message));
            }).catch(e=>console.log(e));
    }

    debounce(delay, fun) {
        clearTimeout(this.timer);
        this.timer = setTimeout(fun, delay);
    }

    changeLang(data, event) {
        this.Server.Request('add_lang_device', {
            lang: event.target.value
        }).then(() => {
                this.Store.dispatch({ type: 'lang/set', payload: event.target.value});
                this.showToast(this.i18next.t('Settings update successfully'), 'success');
            }
        ).catch(e=>console.log(e));
    }


    changeTheme(data, event) {
        this.Server.Request('add_dark_mode_device', {dark_mode:event.target.value}).then(()=>{

            this.showToast(this.i18next.t('Settings update successfully'), 'success')

            if (event.target.value !== 'system') return this.Store.dispatch({type: 'dark_mode/set', payload: event.target.value});
            return this.Store.dispatch({type: 'dark_mode/set', payload: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'});
        }).catch(e=>console.log(e));
    }

    showPhoneStep() {
        this.numberBlock('phone');
        this.changeStateFunction('phone');
    }

    showSMSStep() {
        this.numberBlock('sms');
    }

    showEmailStep() {
        this.numberBlock('email');
        this.changeStateFunction('email');
    }

    showCheckEmailStep() {
        this.numberBlock('check_email');
    }

    showLastStep() {
        this.numberBlock('last');
        this.changeStateFunction('last');
    }

    changeState(param) {
        let activeItem = document.querySelector(".steps_block .active");
        if (activeItem) {
            let nextItem = param === 'prev' ? activeItem.previousElementSibling : document.querySelector("." + param);
            if (nextItem) {
                activeItem.classList.remove("active");
                nextItem.classList.add("active");
                switch (true) {
                    case nextItem.classList.contains("step_phone"):
                        this.showPhoneStep();
                        break;
                    case nextItem.classList.contains("step_sms"):
                        this.showSMSStep();
                        break;
                    case nextItem.classList.contains("step_email"):
                        this.showEmailStep();
                        break;
                    case nextItem.classList.contains("step_check_email"):
                        this.showCheckEmailStep();
                        break;
                    // case nextItem.classList.contains("last_step"):
                    //     this.showLastStep();
                    //     break;
                    default:
                        this.showPhoneStep();
                        break;
                }
            }
        }
    }

    changeStateFunction(number) {
        switch (number) {
            case "phone":
                return !!this.truePhone() && this.validatePhone();
            case "email":
                return !!this.email() && this.validateEmail();
            case "default":
                return !!this.truePhone() && this.validatePhone() && !!this.email() && this.validateEmail() && !!this.checkSMS() && !!this.checkMail();
        }
    }

    validateEmail() {
        return /@[^.]+\.\w/.test(this.email());
    }

    str_rot13() {
        return this.password().replace(/[a-zA-Z]/g, (c) => {
            return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
        });
    }

    SMSCountdown(stopsec) {
        let seconds = Math.floor(new Date().getTime() / 1000);
        let sendSMS = document.getElementById("sendSMS");
        let waitSendSMS = document.getElementById("waitSendSMS");
        let waitSecSMS = document.getElementById("waitSecSMS");

        this.showsecSMS(stopsec - seconds);
        if ((this.showsecSMS() <= 0 || this.stopsend() === 1) && (sendSMS !== null && waitSendSMS !== null)) {
            sendSMS.style.display = 'block';
            waitSendSMS.style.display = 'none';
            return;
        }
        if (waitSecSMS !== null) {
            waitSecSMS.innerHTML = this.showsecSMS();
        }

        const _self = this;
        setTimeout(function () {
            _self.SMSCountdown.call(this, stopsec);
        }.bind(_self), 1000);
    }

    sendSMSRequest() {
        this.Server
            .Request('check_and_send_code', {phone: this.truePhone().replace(/\D/g, '')})
            .then(res => {
                let result = JSON.parse(res);
                if (result.success) {
                    this.errorPhone('');
                    this.smsRevText(false);
                    this.changeState('step_sms');

                    let sendSMS = document.getElementById("sendSMS");
                    let waitSendSMS = document.getElementById("waitSendSMS");
                    this.smsRevText(false);

                    if (sendSMS !== null && waitSendSMS !== null) {
                        sendSMS.style.display = 'none';
                        waitSendSMS.style.display = 'block';
                    }
                    this.stopsend(0);
                    this.showsecSMS(30);
                    const seconds = Math.floor(new Date().getTime() / 1000) + this.showsecSMS();
                    this.SMSCountdown.call(this, seconds);
                }
            }).catch(error => {
            console.log(error);
            this.errorPhone(this.i18next.t(error));
            return false;
        });
    }

    sendCALLRequest() {
        this.Server
            .Request('check_and_phone_call', {phone: this.truePhone().replace(/\D/g, '')})
            .then(res => {
                let result = JSON.parse(res);
                if (result.success) {
                    let sendSMS = document.getElementById("sendSMS");
                    let waitSendSMS = document.getElementById("waitSendSMS");

                    if (sendSMS !== null && waitSendSMS !== null) {
                        sendSMS.style.display = 'none';
                        waitSendSMS.style.display = 'block';
                    }
                    this.stopsend(0);
                    this.showsecSMS(30);
                    const seconds = Math.floor(new Date().getTime() / 1000) + this.showsecSMS();
                    this.SMSCountdown.call(this, seconds);
                    this.changeState('step_sms');
                    this.errorPhone('');
                } else {
                    this.errorPhone(this.i18next.t(result.message));
                }

            }).catch(error => {
            if (error === 'get_sms') {
                return this.sendSMSRequest();
            } else {
                this.errorPhone(this.i18next.t(error));
                return false;
            }
        });
    }

    validateSMS() {
        if (this.sms() === '') {
            this.errorSMS(this.i18next.t('code cannot be empty'));
            return false;
        }

        this.Server
            .Request('check_and_phone_call', {phone: this.truePhone().replace(/\D/g, ''), code: this.sms()})
            .then(res => {
                res = JSON.parse(res);
                if (res.success) {
                    this.checkSMS(true);
                    this.errorSMS('');
                    this.changeState('step_email');
                }
            })
            .catch(e => {
                this.checkSMS(false);
                this.errorSMS(this.i18next.t(e));
            });
    }

    mailCountdown(stopsec) {
        let seconds = Math.floor(new Date().getTime() / 1000);
        let sendEmail = document.getElementById("sendEmail");
        let waitSendEmail = document.getElementById("waitSendEmail");
        let waitSecEmail = document.getElementById("waitSecEmail");
        this.showsecMail(stopsec - seconds);
        if ((this.showsecMail() <= 0 || this.stopsend() === 1) && (sendEmail !== null && waitSendEmail !== null)) {
            sendEmail.style.display = 'block';
            waitSendEmail.style.display = 'none';
            return;
        }
        if (waitSecEmail !== null) {
            waitSecEmail.innerHTML = this.showsecMail();
        }
        const _self = this;
        setTimeout(function () {
            _self.mailCountdown.call(this, stopsec);
        }.bind(_self), 1000);
    }

    showToast(message, type) {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "center",
            style: {
                background: type === "success" ? "info" : "red"
            },
            close: true,
        }).showToast();
    }

    validateMail(email) {
        this.Server
            .Request('check_new_email', {email})
            .then(res => {
                let result = JSON.parse(res);
                if (result.success) {
                    let sendEmail = document.getElementById("sendEmail");
                    let waitSendEmail = document.getElementById("waitSendEmail");

                    if (sendEmail !== null && waitSendEmail !== null) {
                        sendEmail.style.display = 'none';
                        waitSendEmail.style.display = 'block';
                    }
                    this.errorEmail('');
                    this.stopsend(0);
                    this.showsecMail(60);
                    const seconds = Math.floor(new Date().getTime() / 1000) + this.showsecMail();
                    this.mailCountdown.call(this, seconds);
                    this.changeState('step_check_email');
                    this.successfulEmailCode(true);
                }
            })
            .catch(error => {
                this.errorEmail(this.i18next.t(error));
                this.checkMail(false);
            });
    }


    updateEmail() {
        if (this.mail_code() === '') {
            this.errorEmail(this.i18next.t('code cannot be empty'));
            return false;
        }

        this.Server
            .Request('change_email', {
                email: this.email(),
                cod: this.mail_code()
            })
            .then((response) => {
                response = JSON.parse(response);
                if (response.success) {
                    const modal = document.getElementById('changeEmail');
                    if (modal) {
                        modal.classList.remove('show');
                        modal.setAttribute('aria-hidden', 'true');
                        modal.style.display = 'none';

                        const modalBackdrop = document.querySelector('.modal-backdrop');
                        if (modalBackdrop) {
                            modalBackdrop.parentNode.removeChild(modalBackdrop);
                        }
                    }
                    this.checkMail(true);
                    this.errorEmail('');
                    this.showToast(this.i18next.t('Email updated successfully'), 'success');

                } else {
                    this.errorEmail(this.i18next.t('Incorrect code'));
                    this.checkMail(false);
                    this.showToast(this.i18next.t('Incorrect code'), 'error');
                }
            })
            .catch(error => {
                this.errorEmail(this.i18next.t(error));
                this.checkMail(false);
                this.showToast(this.i18next.t(error), 'error');
            });
    }
}
