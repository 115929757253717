import { createSlice } from '@reduxjs/toolkit';
export const messageToStream = createSlice({
    name: 'messageToStream',
    initialState: { message : null, action: false },
    reducers: {
        set: (state, action) => {
            state.message =  action.payload.message;
        },
        start: (state, action) => {
            state.action = true;
        },
        remove: (state, action) => {
            state.action = false;
            state.message = null;
        }
    }
});
export const { set, start, remove } = messageToStream.actions;
export default messageToStream.reducer;