import { createSlice } from '@reduxjs/toolkit';
export const lang = createSlice({
    name: 'lang',
    initialState: { value: 'en' },
    reducers: {
        set: (state, action) => {
            state.value = action.payload;
        }
    }
});
export const { set } = lang.actions;
export default lang.reducer;