import { createSlice } from "@reduxjs/toolkit";

export const contactHandler  = createSlice({
    name: 'contacts',
    initialState: {
        contacts: []
    },
    reducers: {
        add: (state, action) => {
            const existContact = state.contacts.find(contact => contact.id === action.payload.id);
            if (!existContact)
                state.contacts.push(action.payload);
        },
        remove: (state, action) => {
            state.contacts = state.contacts.filter(contact => contact.id !== action.payload.id);
        }
    }
});

export const { add, remove } = contactHandler.actions;
export default contactHandler.reducer;