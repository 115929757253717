import { bindingHandlers } from 'knockout';
import { v4 as uuid } from 'uuid';
const bindImg  = function( element, valueAccessor,q, { Router, Server, Store, i18next }, context ) {
    let  bg = valueAccessor();
    element.setAttribute('hash', bg());


    if(Server === undefined) Server  = context.$component.Server;
    if(Server === undefined && context.$parentContext.$data.hasOwnProperty('Server')) Server  = context.$parentContext.$data.Server;
    let update = ()=>{ Server.links( bg() ).then((file)=>{
        element.setAttribute('hash', bg());
        if(!element.id){
           // element.id = uuid();
            URL.revokeObjectURL(element.src);
            element.src = URL.createObjectURL(file.blob);
            element.alt = file.name;
        }
    }).catch((e)=>{element.alt = e })  }

   if (bg()){
       update()
   }else{
       element.src = '/images/avatar/avatar-placeholder.svg';
   }

    bg.subscribe(()=>update())
    return { controlsDescendantBindings: true };
}

bindingHandlers.image = { init : bindImg, update : bindImg };