import { configureStore } from '@reduxjs/toolkit';
import lang from './features/lang';
import auth from './features/auth';
import dark_mode from './features/dark_mode';
import handler from "./features/handler";
import profile from "./features/profile";
import contactHandler from "./features/contacts";
import blackList from "./features/black_list";
import favoriteHandler from "./features/favorite_contacts";
import openDialogueHandler from "./features/open_dialogue";
import dialogHandler from "./features/dialog";
import message from "./features/message";
import audio from "./features/audio";
import video from "./features/video";
import modalHandler from "./features/modal";
import caller from "./features/caller";
import messageToStream from "./features/messageToStream";

export default configureStore({ reducer: {  lang, auth, dark_mode, handler, profile, contactHandler, blackList, favoriteHandler, openDialogueHandler, dialogHandler, message, audio, video, modalHandler, caller, messageToStream } });
