import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import GLightbox from "glightbox";

export default class chat_chat  extends Handler {
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.question = ko.observable(false);
        this.finishRes = ko.observable(false);
        this.expirationTo = ko.observable('');
        this.questionsIds = ko.observableArray([]);
        this.resId = ko.observable(0);
        this.testId = ko.observable(0);
        this.questionsArr = ko.observableArray([]);
        this.questionId = ko.observable(0);
        this.getInput = ko.observable('');
        this.chatItems = ko.observableArray([]);
        this.offset = ko.observable(0);
        this.limit = 20;
        this.dialogUser = ko.observable('');
        this.chatInput = document.querySelector('#tynChatInput');
        this.dialog_id = ko.observable(0);
        this.messageId = ko.observable(0);
        this.bg = ko.observable(null);
        this.loadBg = ko.observable(0);


        this.chatInput.addEventListener("keypress", (event) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                this.sendAnswer();
            }
        });

        this.unsubscribe = Store.subscribe(() => {
            let state = Store.getState();

            if (state.handler.name === 'initial' ) {
                this.initNewDialog();
                return;
            }
            if(state.openDialogueHandler.id) {
                this.initNewDialog();
            }


        });



        this.initNewDialog();
    }

    initNewDialog() {
        if (this.dialog_id() !== Number(this.Store.getState().openDialogueHandler.id)) {
            this.chatItems([]);
            this.dialog_id(Number(this.Store.getState().openDialogueHandler.id));
            this.getRecipient();
            this.userSubscribe();
        }
    }

    userSubscribe() {
        if (this.dialog_id() > 0) {
            this.Server.Subscribe('user_profile',this.dialog_id()).then(Subscribe=>{
                let User = ko.mapping.fromJS(Subscribe.get());
                this.dialogUser(User);
                Subscribe.on('update', (newVal) => {
                    this.updateField(User, newVal, 'avatar', 'name', 'surname', 'bg');
                });
            });
        }
    }


    updateStatus() {
        let sendingArr = [];
        ko.utils.arrayMap(this.chatItems(), (message) => {
            if (message.status() === 0) {
                sendingArr.push(message.id());
                message.status(1);
            }
        });

        if (!sendingArr.length) return true;

        this.Server
            .Request('update_statuses', {
                value: {status: 1, arr:sendingArr, userid:this.dialog_id()}
            }).then(res=>{
            let result = JSON.parse(res);
            if (result) {
                return true;
                //result = JSON.parse(result);
                //this.Server.Request('update_notify',{userid:this.dialog_id()}).then((result)=>{
                    //console.log(result);
                //}).catch(e => console.log(e));
            }
        }).catch(e => console.log(e));
    }




    isExist(id) {
        return ko.utils.arrayFilter(this.chatItems(), (item) => {
            if (item.id() === id) return id;
        }).length >= 1;
    }

    getRecipient() {
        if (this.dialog_id()) {
            this.getListDialogs();
            // this.getUserDialog(this.dialog_id());
        }
    }

    backRoad() {
        document.getElementById('tynMain').classList.remove('main-shown');
        return this.Router.navigate('/');
    }
    validateAnswer(answer, max) {
        const regex = /^(\d+)(,\d+)*$/;

        if (!regex.test(answer)) {
            return false;
        }
        if(!max) {
            return true;
        }


        const numbers = answer.split(',').map(Number);
        for (let num of numbers) {
            if (isNaN(num) || num<1 || num > max) {
                return false;
            }
        }

        return true;
    }

    sendAnswer() {
        if(!this.question() || !this.validateAnswer(this.getInput(), this.question().length) || this.finishRes()) {
            //alert('введите номер ответа');
            return false;
        }


        this.Server
            .Request('add_answer', {
                options: this.question(),
                question_id: this.questionId(),
                result_id:this.resId(),
                answer_text: this.getInput(),
                expiration_to: this.expirationTo(),
                tests: this.questionsIds(),
                type: 'message'
            }).then(res => {
            let result = JSON.parse(res);
            if (result) {

                if (this.question()[this.getInput()-1] && this.question()[this.getInput()-1].symptoms) {
                    // Push each symptom individually into questionsArr
                    this.question()[this.getInput()-1].symptoms.forEach(symptom => {
                        this.questionsArr().push(symptom);
                    });
                    this.questionsArr.sort((a, b) => a - b);
                }
                this.chatItems.push(ko.mapping.fromJS(result));
                this.chatInput.style.height = '40px';
                this.getInput('');
                this.getQuestion();
            }
        }).catch(e => console.log(e));
    }

    newTest() {
        this.Server
            .Request('new_test', {tests_id:this.testId()}).then(res => {
            let result = JSON.parse(res);
            if (result) {
                this.finishRes(false);
                this.questionsArr(result.q_arr);
                this.resId(result.res_id);
                this.getQuestion();
            }
        }).catch(e => console.log(e));
    }

    checkResult(id) {
        this.Server
            .Request('check_test_result', {tests_id:id}).then(res => {
            let result = JSON.parse(res);
            if (result) {
                this.resId(result.res_id);
                this.testId(id)
            }
        }).catch(e => console.log(e));
    }

    continueTest() {
        this.Server
            .Request('get_filtered_questions', {result_id:this.resId(), tests_id:this.testId()}).then(res => {
            let result = JSON.parse(res);
            if (result) {
                this.questionsArr(result);
                this.getQuestion();
            }
        }).catch(e => console.log(e));
    }

    getQuestion() {
        // Проверяем, что массив не пустой
        if (this.questionsArr().length > 0) {
            // Получаем первый элемент массива и удаляем его
            let id = this.questionsArr().shift();

            this.Server
                .Request('get_next_question', {question_id:id, result_id:this.resId()}).then(res => {
                let result = JSON.parse(res);
                if (result.calculated && result.calculated === 'IMT') {
                    this.question(result.options);
                    this.questionId(result.id);
                    this.expirationTo(result.expire);
                    this.questionsIds(result.tests);
                    this.Server
                        .Request('add_answer', {
                            options: this.question(),
                            question_id: this.questionId(),
                            result_id:this.resId(),
                            answer_text: result.result_answer,
                            expiration_to: this.expirationTo(),
                            tests: this.questionsIds(),
                            type: 'message'
                        }).then(res => {
                        let result = JSON.parse(res);
                        if (result) {
                            this.getQuestion();
                        }
                    }).catch(e => console.log(e));
                } else {
                    this.question(result.options);
                    this.questionId(result.id);
                    this.expirationTo(result.expire);
                    this.questionsIds(result.tests);

                    this.getInput('');
                    this.chatItems.push(ko.mapping.fromJS(result));
                    this.scrollToTop();

                }
            }).catch(e => console.log(e));
        } else {
            this.chatItems.push(ko.mapping.fromJS({finish:true}));

            // сохранить результат
            this.saveResult();
            this.scrollToTop();
        }
    }
    saveResult() {
        this.Server
            .Request('save_result', { result_id:this.resId()}).then(() => {
            this.finishRes(true);
        }).catch(e => console.log(e));
    }
    getListDialogs(last_message_id, isPrev = true, searchMessageId = null) {
        this.Server.Request('get_messages_by_user', {userid:this.dialog_id(), limit: this.limit, status:'active', searchID: last_message_id, isPrev})
            .then(res => {
                const response = JSON.parse(res);

                if (response.success) {


                    response.messages.reverse().forEach(message => {

                        if (this.isExist(message.id)) return false;

                        if (['images', 'audio', 'video_file', 'contact', 'files'].includes(message.type)) message.message_text = JSON.parse(message.message_text);

                        if (message.type === 'contact') {

                            const contactProfile = ko.mapping.fromJS({avatar: '', name: '', surname: '', user_id: 0, nickname: ''});

                            contactProfile.user_id(message.message_text.id);
                            this.Server.Subscribe('user_profile', message.message_text.id).then(Subscribe => {
                                this.updateField(contactProfile, Subscribe.get(), 'name', 'surname', 'avatar', 'nickname');
                                Subscribe.on('update', (newVal) => {
                                    this.updateField(contactProfile, newVal, 'name', 'surname', 'avatar', 'nickname');
                                });
                            });
                            message = {...message, ...contactProfile};
                        } else if(message.type === 'files') {

                            message.message_text.forEach((item, index) => {
                                const fileData = ko.mapping.fromJS({file_name: '', file_extension: '', file_size: 0});
                                this.Server.links(item.object_id).then((file) => {
                                    fileData.file_name(file.name);
                                    fileData.file_extension(file.name.split('.')[1]);
                                    fileData.file_size(this.niceBytes(file.size));
                                }).catch(e=>console.log(e));
                                message.message_text[index] =fileData;
                            });

                        }

                        if (isPrev) {
                            this.chatItems.unshift(ko.mapping.fromJS(message));
                        } else {
                            this.chatItems.push(ko.mapping.fromJS(message));
                        }

                    });


                    if (!!searchMessageId) {
                        const elem = document.querySelector(`.tyn-reply-item[data-id="${last_message_id}"]`);
                        elem.scrollIntoView({ block: "center", behavior: "smooth" });
                        elem.classList.add('choose-search-message-focus');

                        setTimeout(() =>{
                            elem.classList.remove('choose-search-message-focus');
                        },800);
                    }

                    GLightbox({touchNavigation: true, loop: true, autoplayVideos: true});

                    this.updateStatus();
                }
            }).catch((e) => console.log('getListDialogs ', e));
    }
    scrollToTop() {
        document.querySelector('.chat-list').scrollIntoView({block: "end"});
    }


}
