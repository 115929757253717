import { createSlice } from "@reduxjs/toolkit";

export const blackListHandler  = createSlice({
    name: 'black_list',
    initialState: {
        black_list: []
    },
    reducers: {
        add: (state, action) => {
            const existContact = state.black_list.find(black_list => black_list.id === action.payload.id);
            if (!existContact)
                state.black_list.push(action.payload);
        },
        remove: (state, action) => {
            state.black_list = state.black_list.filter(black_list => black_list.id !== action.payload.id);
        }
    }
});

export const { add, remove } = blackListHandler.actions;
export default blackListHandler.reducer;