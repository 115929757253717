import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";

export default class modals extends Handler {
    constructor({Store, Router, Server, i18next}) {
        super({Store, Router, Server, i18next});
        this.Audio = ko.observableArray([]);
        this.Video = ko.observableArray([]);
        this.audioRemoteMuted = ko.observable(false);
        this.User = ko.mapping.fromJS(Store.getState().profile.profile);
        this.caller = ko.mapping.fromJS(Store.getState().caller);
        this.UserAudio = ko.mapping.fromJS(Store.getState().audio);
        this.UserVideo = ko.mapping.fromJS(Store.getState().video);
        this.modalDataObject = ko.observable({id:0});

        this.unsubscribe = Store.subscribe(() => {
            let state = Store.getState();
            const profile = state.profile.profile;
            this.User.name(profile.name);
            this.User.surname(profile.surname);
            this.User.id(profile.id);

            const UA = Store.getState().audio;

            this.caller.id(state.caller.id);
            this.caller.kind(state.caller.kind);
            this.caller.userid(state.caller.userid);


                this.UserAudio.id(UA.id);
                this.UserAudio.name(UA.name);
                this.UserAudio.surname(UA.surname);
                this.UserAudio.bg(UA.bg);
                this.UserAudio.avatar(UA.avatar);
                this.UserAudio.gender(UA.gender);
                if (UA.id) {
                    Server.Subscribe('user_profile',UA.id).then(s=>s.on('update',(newValue)=>Store.dispatch({ type: 'audio/set', payload: newValue })));
                }



            const UV = state.video;

                this.UserVideo.id(UV.id);
                this.UserVideo.name(UV.name);
                this.UserVideo.surname(UV.surname);
                this.UserVideo.bg(UV.bg);
                this.UserVideo.avatar(UV.avatar);
                this.UserVideo.gender(UV.gender);
                if (UV.id) {
                    Server.Subscribe('user_profile',UV.id).then(s=>s.on('update',(newValue)=>Store.dispatch({ type: 'video/set', payload: newValue })));
                }



            if (state.modalHandler.name === 'deleteModalMessage') {
                this.modalDataObject(state.modalHandler.modalData);
            }

            if (state.modalHandler.name === 'deleteMessageForEveryone') {
                this.modalDataObject(state.modalHandler.modalData);
            }

        });


        this.searchname = ko.observable('');
        this.seachList = ko.observableArray([]);
        this.contactAddInList = ko.observableArray([]);
        this.textTheAddButton = ko.observable(false);
        document.querySelector('#addContact').addEventListener('hide.bs.modal', () => {
            this.contactAddInList.removeAll();
            this.searchname('');
        });

        this.searcChat = ko.observable('');
        document.querySelector('#newChat').addEventListener('hide.bs.modal', () => {
            this.contactAddInList.removeAll();
            this.searcChat('');
        });

    }

    async applyStream(){

        this.Store.dispatch({ type: 'messageToStream/start' });

        this.Server.Ringtone.pause();
        this.Server.gudokg.pause();
        this.Server.gudokm.pause();
        let caller = this.Store.getState().caller;
        this.Store.dispatch({ type: 'caller/reset' });
        if(Number(caller.userid)!==Number(this.Store.getState().profile.profile.id)){
            this.Server.getTrack(caller.id)
                .then(track=>{
                    if(track.kind === 'audio'){
                        this.Audio.push({ stream:new MediaStream([track]), muted :  this.audioRemoteMuted });
                    }
                    if(track.kind === 'video'){
                        this.Video.push({ stream:new MediaStream([track]), muted :  this.audioRemoteMuted });
                    }
                }).catch(e=>console.log(e));
        }
    }

    async stopStream(){
        //console.log('stopStream');
        this.Store.dispatch({ type: 'video/set', payload:  { id: null, name:null, surname:null, bg:null, avatar:null, gender:null } });
        this.Store.dispatch({ type: 'audio/set', payload:  { id: null, name:null, surname:null, bg:null, avatar:null, gender:null } });
        this.Store.dispatch({ type: 'caller/reset' });
        this.Server.stopTracksCansume();
        this.Server.stopTracks();
        this.Server.Ringtone.pause();
        this.Server.gudokg.pause();
        this.Server.gudokm.pause();
        new bootstrap.Modal(document.getElementById('callingScreen')).hide();
        document.getElementsByClassName("modal-backdrop")[0].remove();
    }

    toggleSwap() {
        document.getElementById('videoLarge').classList.toggle('swapped');
        document.getElementById('videoSmall').classList.toggle('swapped');
    }

    deleteMessage() {
        const {id, recipient_id, sender_id} = this.modalDataObject();
        return this.Server.Request('delete_message', {id, recipient_id, sender_id})
            .then(() => this.Store.dispatch({type: 'handler/set', payload: {name: 'delete_message', params: {id, data: {id, recipient_id, sender_id}}}}))
            .catch((e) => console.log(e));
    }
    deleteMessageForEveryone() {
        const {id, id2id, recipient_id, sender_id} = this.modalDataObject();
        return this.Server.Request('delete_message_both', {id, id2id, recipient_id, sender_id})
            .then(() => this.Store.dispatch({type: 'handler/set', payload: {name: 'delete_message_both', params: {id, data: {id, recipient_id, sender_id}}}}))
            .catch((e) => console.log(e));
    }

    debounce(delay, fun) {
        clearTimeout(this.timer);
        this.timer = setTimeout(fun, delay);
    }

    searchContactsByNameEvent(tab) {
        return {
            input: (data, event) => {
                if (tab === 'contacts') {
                    if (event.target.value.length > 0) {
                        this.debounce(400, () => {
                            this.Server.Request('get_search_profiles', {search_text: event.target.value}).then(response => {
                                response = JSON.parse(response);
                                this.seachList([]);
                                if (response.success) {
                                    response.profiles.forEach(user => {

                                        let resultProfile = ko.mapping.fromJS({
                                            name: '',
                                            surname: '',
                                            avatar: '',
                                            nickname: '',
                                            id: 0,
                                            authid: 0,
                                            is_friend: Object.values(user)[0]
                                        });

                                        this.seachList.push(resultProfile);

                                        this.Server.Subscribe('user_profile', Object.keys(user)[0]).then(profile => {
                                            this.updateField(resultProfile, profile.get(), 'name', 'surname','nickname' , 'avatar', 'id', 'authid');
                                            profile.on('update', (newValue) => {
                                                this.updateField(resultProfile, newValue, 'name', 'surname','nickname' , 'avatar');
                                            });
                                        });
                                    });
                                }
                            });
                        });
                    } else {
                        this.seachList([]);
                    }
                }
            }
        };
    }

    searchContacts() {
        return {
            input: (data, event) => {
                if (event.target.value.length > 0) {
                    this.debounce(400, () => {
                        this.Server.Request('get_search_contacts', {search_text: event.target.value}).then(response => {
                            response = JSON.parse(response);
                            this.seachList([]);
                            if (response.success) {
                                response.profiles.forEach(user => {
                                    if (this.Store.getState().openDialogueHandler.id !== user) {
                                        let resultProfile = ko.mapping.fromJS({
                                            name: '',
                                            surname: '',
                                            avatar: '',
                                            nickname: '',
                                            id: 0,
                                            authid: 0,
                                            is_friend: true
                                        });
                                        this.seachList.push(resultProfile);
                                        this.Server.Subscribe('user_profile', user).then(profile => {
                                            this.updateField(resultProfile, profile.get(), 'name', 'surname','nickname' , 'avatar', 'id', 'authid');
                                            profile.on('update', (newValue) => {
                                                this.updateField(resultProfile, newValue, 'name', 'surname','nickname' , 'avatar');
                                            });
                                        });
                                    }
                                });
                            }
                        });
                    });
                } else {
                    this.seachList([]);
                }
            }
        };
    }

    updateField(profile, newVal, ...fieldsName) {
        fieldsName.forEach(name => {
            profile[name](newVal[name]);
        });
    }

    addUserSubscribe(id) {
        this.Server.Subscribe('user_profile',id).then(Subscribe=>{
            let user = Subscribe.get();
            this.Server.Subscribe('profile_settings',id).then(res=>{
                this.Store.dispatch({type: 'contacts/add', payload: {...user, ...res.get()}});
            });
        });
    }

    sendContact(nickname, id) {
        this.Server
            .Request('send_message', {
                // value: '<a href="'+window.location.protocol  + '//' + window.location.host + '/contacts/' + nickname+'">'+ nickname +'</a>',
                value: JSON.stringify({"id":id,"link":`${window.location.protocol}//${window.location.host}/contacts/${nickname}`}),
                user_id: this.Store.getState().openDialogueHandler.id,
                type: 'contact'
            }).catch(e => console.log(e));
    }

    addNewContact(contact_user_id) {
        this.Server.Request('add_contact', {contact_user_id})
            .then(() => {
                this.textTheAddButton(true);
                this.contactAddInList.push(contact_user_id);
                this.addUserSubscribe(contact_user_id);
            })
            .catch((e) => console.error(e));
    }

    isContact(speaker) {
        return ko.utils.arrayFilter(this.contactAddInList(), (item) => {
            if (item === speaker) return item;
        }).length >= 1;
    }

    startChatDialog(id) {
        this.Router.navigate('/dialog/' + id);
    }

}
