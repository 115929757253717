import 'knockout-mapping';
import Handler from "engine/Handler";
import ko from "knockout";
import 'binding/resize';
export default class confirm_telegram  extends Handler{
    constructor({ Store, Router, Server, i18next  }) {
        super({ Store, Router, Server, i18next });
        this.urlParams = new URLSearchParams(window.location.search);
        this.code = ko.observable(this.urlParams.get('code'));
        this.chatId = ko.observable(this.urlParams.get('chatId'));
        this.isConfirmed= ko.observable(false);
        this.isRegistered= ko.observable(Store.getState().auth.value);
        this.alertText= ko.observable(false);
        console.log(this.code() ,this.chatId() ,Store.getState().auth.value)
        if (this.code() && this.chatId() && Store.getState().auth.value) {
            this.confirmTelegram();
        } else if(this.code() && this.chatId()) {
            Store.dispatch({type: 'handler/set', payload: {name: 'telegramAuth',params: {code: this.code(), chatId: this.chatId()}}});
        }
    }
    setTgChats() {
        this.Server.Request('add_tg_chats', {
            chat_id: this.chatId()
        }).then(res =>{

            let result = JSON.parse(res);
            if (result.success) {
                this.isConfirmed(true)
                this.alertText(false)
            }
        }).catch(e=>console.log('add_tg_chats', e))
    }


    confirmTelegram() {
        this.Server
            .Request('confirm_telegram', {chat_id: this.chatId(), cod: this.code()})
            .then((res) => {
                let result = JSON.parse(res);
                console.log(result)
                if (result.success) {
                    return this.setTgChats();
                } else {
                    this.alertText(result.text)
                    console.log('confirm_telegram', result)
                }
            }).catch(e=>this.alertText(e))
    }
}
