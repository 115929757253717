import { createSlice } from '@reduxjs/toolkit';

export const modalHandler  = createSlice({
    name: 'modal',
    initialState: {
        name: 'hello',
        modalData: {}
    },
    reducers: {
        setDataModal: (state, action) => {
            state.name = action.payload.name;
            state.modalData = action.payload.data;
        }
    }
});

export const { setDataModal } = modalHandler.actions;
export default modalHandler.reducer;
