import { detectDevice, Device } from 'mediasoup-client';
import  Record  from './Record';
import CryptoJS from "crypto-js";
import { v4 as uuid } from 'uuid';
export default class network extends Record{
    constructor(serverUrl) {
        super(serverUrl);
        this.Ringtone =  new Audio('/sound/ring.mp3')
        this.gudokg =  new Audio('/sound/gudokg.mp3')
        this.gudokm =  new Audio('/sound/gudokm.mp3')
        this.Ringtone.volume = 0.2;
        this.gudokg.volume = 0.2;
        this.gudokm.volume = 0.2;

        const changeTitle = function() {
            this.Oldtitle = document.title;
            this.title = function () {
                const title = document.title;
                document.title = (title === "Call to messenger" ? "" : "Call to messenger");
            }
        };

        changeTitle.prototype.start = function() {
            this.timer = setInterval(this.title, 1000);
        };

        changeTitle.prototype.stop = function() {
            clearInterval(this.timer);
            document.title = this.Oldtitle;
        };

        const timerTitle = new changeTitle();

        this.Ringtone.addEventListener('playing',() => timerTitle.start(),false);
        this.gudokg.addEventListener('playing',() => timerTitle.start(),false);
        this.gudokm.addEventListener('playing',() => timerTitle.start(),false);
        this.Ringtone.addEventListener("pause", () => {
            this.Ringtone.currentTime = 0.0;
            timerTitle.stop()
        });
        this.gudokg.addEventListener("pause", () => {
            this.gudokg.currentTime = 0.0;
            timerTitle.stop()
        });
        this.gudokm.addEventListener("pause", () => {
            this.gudokm.currentTime = 0.0;
            timerTitle.stop()
        });
        this.VER=1;
        this.storeName='files';
        this.dbName = 'Chat'
        this.request = indexedDB.open(this.dbName, this.VER);
        this.request.onupgradeneeded = this.onupgradeneededFile.bind(this);
        this.request.onsuccess = (event) => { this.db = event.target.result; };
        this.request.onblocked = () => {  throw new Error('Access indexedDB blocked') }

        this.Storage  = {
            getItem:async (key)=>{
                if(typeof AsyncStorage !== 'undefined'){
                    return  AsyncStorage.getItem(key);
                }else{
                    return  localStorage.getItem(key);
                }
            },
            setItem:async (key,value)=>{
                if(typeof AsyncStorage !== 'undefined'){
                    return  AsyncStorage.setItem(key,value);
                }else{
                    return  localStorage.setItem(key,value);
                }
            }
        };


        this.serverUrl = serverUrl;
        this.filesOffset = [];
        this.dataProducerId = false;
        this.device = false;
        this.config = false;
        this.requestToServer = {};
        this.fileDownloadResolve = {};
        this.fileDownloadResolveArray = {};
        this.fileDownload = {};
        this.fileDownloadLength = {};
        this.subscribe = {};
        this.eventers = {};
        this.subscribeLocalAwait = {};
        this.fragment = {};
        this.fragmentCounter = {};
        this.subscribeLocal = {};
        this.Auth = false;
        this.connectionOldTimer = 0;
        this.connectionTimer = 0;
        this.Timer = 6500;
        this.PingTimer = false;
        this.produceData = false;
        this.consumerData = false;
        this.consumerDataGlobal = false;
        this._uuidClient = false;
        this.Tp = false;
        this.Tr = false;
        this.isConnected = false;
        this.Requests = [];
        this.Producers = [];
        this.cunsumeList = [];
        this.cunsumeTracks = [];
        this.Tracks = [];
        this.uuic = null;
        this.config = null;
        this.video_codec = [
            {
                rid: 'r0',
                maxBitrate: 100000,
                scalabilityMode: 'S1T3',
            },
            {
                rid: 'r1',
                maxBitrate: 300000,
                scalabilityMode: 'S1T3',
            },
            {
                rid: 'r2',
                maxBitrate: 900000,
                scalabilityMode: 'S1T3',
            },
            {
                maxBitrate      : 5000000,
                scalabilityMode :  'L3T3_KEY'
            },
            {
                scaleResolutionDownBy : 1,
                maxBitrate            : 5000000,
                scalabilityMode       : ' '
            },
            {
                scaleResolutionDownBy : 2,
                maxBitrate            : 1000000,
                scalabilityMode       : 'L1T3'
            },
            {
                scaleResolutionDownBy : 4,
                maxBitrate            : 500000,
                scalabilityMode       : 'L1T3'
            }
        ];
        if(CryptoJS.AES){
            this.encr = CryptoJS.AES.encrypt;
            this.decr = CryptoJS.AES.decrypt;
        }

        let ti = setInterval(()=>this.isAirplaneMode().then((airplaneModeOn) => {
            if(airplaneModeOn){
                clearInterval(ti);
                this.produceData = false;
            }
        }), 1000);

    }




    get uuidClient() {
        return this._uuidClient;
    }

    set uuidClient(uuidClient) {
        if(this._uuidClient !== false && this._uuidClient!==uuidClient){
            throw new Error('The local Uuid has been replaced');
        }
        this._uuidClient = uuidClient;
    }

    event(eventName,data = null) {

        if(this[eventName]){
            this[eventName]();
        }


        if(this[eventName+'EVENT']){
            this[eventName+'EVENT'].forEach(c=>c(data));
        }
    }

    on(eventName,callback) {
        if(this[eventName+'EVENT']){
            this[eventName+'EVENT'].push(callback);
        }else{
            this[eventName+'EVENT'] = [];
            this[eventName+'EVENT'].push(callback);
        }
    }

    disconnected() {
        this.isConnected = false;
        this.refreshConnection().catch(()=>this.connect());
    }


    //test

    connected () {
        this.isConnected = true;
        let Requests = [...this.Requests];
        this.Requests = [];
        Requests.forEach(message => this.isSend(message));
    }


    getUuid() {
        if(typeof uuid !== 'undefined'){
            return uuid();
        }else{
            return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        }
    }

    async isAirplaneMode() {
        if(typeof DeviceInfo !== 'undefined'){
            return DeviceInfo.isAirplaneMode();
        }else{
            return false;
        }
    }

    async deviceName() {
        if(typeof DeviceInfo !== 'undefined'){
            let systemVersion = DeviceInfo.getSystemVersion();
            console.log({systemVersion});
            let version = DeviceInfo.getVersion();
            console.log({version});
            DeviceInfo.getDeviceName().then((deviceName) => {
                console.log({deviceName});
            });
            DeviceInfo.getManufacturer().then((manufacturer) => {
                console.log({manufacturer});
            });
            DeviceInfo.supportedAbis().then((abis) => {
                console.log({abis});
            });
            return DeviceInfo.isAirplaneMode();
        }else{
            return false;
        }
    }

    encrypt(data) {
        return this.encr(JSON.stringify({data}),'747E314D23DBC624E971EE59A0BA6D28',).toString();
    }

    decrypt(response) {
        try {
            return JSON.parse(this.decr(response,'747E314D23DBC624E971EE59A0BA6D28',).toString(CryptoJS.enc.Utf8)).data;
        }catch (error) {
            throw new Error('decrypt failed');
        }
    }

    isSend(message) {
        if(this.produceData && this.produceData.readyState === 'open' && this.isConnected){
            this.send(message);
            return true;
        }else{
            this.Requests.push(message);
            return true;
        }
    }

    message(){

    }

    messageDataChannel(message){

        if(message.hasOwnProperty('data')){
            //this.networkError();
            this.event('message',JSON.parse(this.decrypt(message.data)));
        }

        if(message.hasOwnProperty('subscribe')){

            message.subscribe = JSON.parse(this.decrypt(message.subscribe));
            let current = (typeof JSON.parse(message.subscribe).subscribe === 'object')?JSON.parse(message.subscribe).subscribe:JSON.parse(message.subscribe);
            let RequestId = message.RequestId;
            delete message.RequestId;
            if(message.subscribe.hasOwnProperty('error')){
                this.subscribe[RequestId].reject(message.subscribe.error);
                delete this.subscribe[RequestId];
                return false;
            }

            if(this.eventers[RequestId]){
                this.eventers[RequestId].current=current;
                this.eventers[RequestId][message.type](current);
                if(message.type === 'remove'){
                    delete this.eventers[RequestId];
                }
            }

            if(this.subscribe[RequestId]){
                let _self = this;
                let event = {
                    id:_self.getUuid(),
                    get(key = false){
                        if(key === false){
                            return this.current;
                        }
                        if(this.current[key]!== undefined){
                            return this.current[key];
                        }else{
                            throw new Error(key+" - get method, field not found from object:"+this.className+" id:"+this.idRecord);
                        }
                    },
                    getReducer:()=>{
                        return[
                            (state, newValue)=>{
                                event.current = {...state,...newValue};
                                return event.current;
                            },
                            { ...event.current }
                        ];
                    },
                    className:_self.subscribe[RequestId].className,
                    idRecord:_self.subscribe[RequestId].idRecord,
                    set(key,value){
                        if(this.current[key]!== undefined){
                            this.current[key]=value;
                        }else{
                            throw new Error(key+" - set method, field not found from object:"+this.className+" id:"+this.idRecord);
                        }
                        return this;
                    },
                    update(message){
                       this.eu.forEach(c=>c(message))
                    },
                    data(message){
                        for (let id in this.ed) {
                            this.ed[id](message);
                        }
                    },
                    remove(message){
                        for (let id in this.er) {
                            this.er[id](message);
                        }
                    },
                    unsubscribe(){
                        _self.Request('/unsubscribe',{ className: this.className, idRecord: this.idRecord,RequestId }).then(()=>{
                            if(_self.eventers[RequestId]){
                                delete _self.eventers[RequestId];
                            }
                        }).catch(()=> {
                            throw new Error('unsubscribe failed');
                        });
                    },
                    save(){

                        _self.Request(this.className+'_save',this.current)
                            .then(()=>{

                            })
                            .catch(()=> { throw new Error(this.className+'_save'+' Request failed'); });

                    },
                    er:{},
                    eu:[],
                    ed:{},
                    current,
                    on(eventName,callback) {
                        switch (eventName) {
                            case 'update':
                                this.eu.push(callback);
                                break;
                            case 'data':
                                if(!this.ed[this.id]){
                                    this.ed[this.id] = [];
                                }
                                this.ed[this.id].push(callback);
                                callback(this.current);
                                break;
                            case 'remove':
                                if(!this.er[this.id]){
                                    this.er[this.id] = [];
                                }
                                this.er[this.id].push(callback);
                        }
                    }
                };
                this.eventers[RequestId] = event;
                let eName = this.subscribe[RequestId].className+this.subscribe[RequestId].idRecord;
                this.subscribeLocalAwait[eName] = event;
                if(this.subscribeLocal[eName]){
                    this.subscribeLocal[eName]
                        .forEach(c=>c(event))
                    this.subscribeLocal[eName] = [];
                }
                this.subscribe[RequestId].resolve(event);
                delete this.subscribe[RequestId];
            }
        }

        if(message.hasOwnProperty('response')){
            message.response = JSON.parse(this.decrypt(message.response));

            if(this.requestToServer[message.id].timeout){
                clearInterval(this.requestToServer[message.id].timeout);
            }
            if(message.response.hasOwnProperty('error')){

                this.requestToServer[message.id].reject(message.response.error);

                delete this.requestToServer[message.id];
            }
            if(this.requestToServer[message.id]){
                this.requestToServer[message.id].resolve(message.response);
                delete this.requestToServer[message.id];
            }
        }
    }

    async Subscribe (className,idRecord) {
        let url = 'subscribe';
        let data = { className, idRecord, uuid:this.uuidClient };
        if(this.subscribeLocal[className+idRecord]){
            return new Promise((resolve)=> {
                if(this.subscribeLocalAwait[className+idRecord]){
                    resolve({...this.subscribeLocalAwait[className+idRecord],id:this.getUuid()});
                }else{
                    this.subscribeLocal[className+idRecord].push((d)=>{
                        resolve(d)
                    });
                }
            });
        }

        this.subscribeLocal[className+idRecord] = [];
        return new Promise((resolve, reject)=> {
            this.isAirplaneMode().then((airplaneModeOn) => {
                if(airplaneModeOn){
                    this.produceData = false;
                    reject({ error:{ isAirplaneMode:true } });
                }else{
                    let id = this.getUuid();
                    if(this.isSend({ subscribe:this.encrypt(JSON.stringify(data)), id ,url:url}) === false){
                        reject({ error:{ connect:false } } );
                    }else{
                        this.subscribe[id] = { resolve, reject,className ,idRecord};
                    }
                }
            });
        });
    }

    async Request (url,data = {}, options = { timeout : 20000 } ) {
        return new Promise((resolve, reject)=> {
            this.isAirplaneMode().then((airplaneModeOn) => {
                if(airplaneModeOn){
                    this.produceData = false;
                    reject({error:{isAirplaneMode:true}});
                }else{
                    let id = this.getUuid();
                    if(this.isSend({ request:this.encrypt(JSON.stringify(data)), id ,url:url}) === false){
                        reject({ error:{ connect:false } } );
                    }else{
                        this.requestToServer[id] = { resolve, reject, timeout : setTimeout( () =>{
                                if(!options.timeout) reject({ error : 'timout request' } );
                            }, options.timeout ) };
                    }
                }
            });
        });
    }

    fileSlice (file, chunkStart, chunkEnd, type)  {
        if(file.slice) {
            return file.slice([chunkStart], [chunkEnd], type);
        }
        if(file.webkitSlice){
            return file.webkitSlice([chunkStart], [chunkEnd], type);
        }

        if(file.mozSlice) {
            return file.mozSlice([chunkStart], [chunkEnd], type);
        }
    }

    sendFiles(files, maxSpeed = 10) {
        maxSpeed = (1000/(1000000/16000)) / (maxSpeed/8);
        let arrayRequests = [];
        Array.from(files).forEach(file=>{
            arrayRequests.push(

                this.Request('addFile',{ name:file.name, size:file.size, type:file.type, lastModified:file.lastModified })
                    .then(res=>{
                        let { UserFile } = JSON.parse(res);
                        return this.Subscribe('user_files', UserFile.id )
                            .then(SubscribeFile=>{
                                if(SubscribeFile.get('offset')<file.size){
                                    this.sendFile(file,SubscribeFile.get(),maxSpeed);
                                }
                                return SubscribeFile;
                            });
                    })

            );
        });
        return Promise.all(arrayRequests);
    }

    messageBinDataChannel(chunk){
        const view = new DataView(chunk);
        let fileChunks =  chunk.slice(0,chunk.byteLength-12);

        let fileID = view.getInt32(chunk.byteLength-12);
        let fileLength = view.getInt32(chunk.byteLength-8);
        let fileOffset = view.getInt32(chunk.byteLength-4);

        if(fileOffset === fileChunks.byteLength && fileOffset === fileLength){
            return;
        }
        if(fileOffset === 0){
            this.fileDownload[fileID] = [];
            this.fileDownloadLength[fileID] = 0;
        }

        this.fileDownloadLength[fileID] = this.fileDownloadLength[fileID] +  fileChunks.byteLength;
        if(this.fileDownloadLength[fileID] <= fileLength){
            this.fileDownload[fileID].push(fileChunks);
        }
        if(this.fileDownloadLength[fileID] >= fileLength ){
            this.fileDownloadResolve[fileID](this.fileDownload[fileID]);
            delete this.fileDownloadResolve[fileID];
            delete this.fileDownload[fileID];
            delete this.fileDownloadLength[fileID];
        }


    }


    onupgradeneededFile(event){
        const oS = event.target.result.createObjectStore(this.storeName,{ keyPath: "iduniq", autoIncrement: true });
        oS.createIndex('id', 'id', { unique: false })
        oS.createIndex('object_id', 'object_id', { unique: false })
        oS.createIndex('blob', 'blob', { unique: false })
        oS.createIndex('offset', 'offset', { unique: false })
        oS.createIndex('size', 'size', { unique: false })
        oS.createIndex('type', 'type', { unique: false })
    }

    addFileToStore(file){
        this.db.transaction(this.storeName, "readwrite")
            .objectStore(this.storeName)
            .put(file)
            .onsuccess = () => { };
    }

    async getFilesFromStore(object_id){
        return new Promise((resolve, reject) => {
            const cursorRequest = this.db.transaction(this.storeName, "readonly").objectStore(this.storeName).index('id').openCursor(null, 'next');
            cursorRequest.onerror = () => reject('error transaction');
            cursorRequest.onsuccess = (e) => {
                if (e.target.result) {
                    if(e.target.result.value.object_id === object_id){
                        resolve( e.target.result.value );
                    } else {
                        e.target.result.continue();
                    }
                }else{
                    reject('notfound')
                }
            };
        });
    }

    async fileByID(id) {
        return new Promise(( resolve, reject )=>{
            this.Subscribe('user_files', id)
                .then(file => {
                    if(file.get('object_id')){
                        resolve(this.links(file.get('object_id')));
                    }else{
                        file.on('update', (newValue) => {
                            if(newValue.object_id){
                               resolve(this.links(newValue.object_id));
                            }
                        });
                    }
                });
        })
    }

    async links(object_id) {
        if(!(this.fileDownloadResolveArray[object_id]===undefined)){
            return new Promise(( resolve, reject )=>{
                if(!!object_id){
                    this.fileDownloadResolveArray[object_id].push({resolve,reject});
                }else{
                    reject('object_id cannon by empty');
                }
            })
        }
        this.fileDownloadResolveArray[object_id] = [];
        return new Promise(( resolve, reject )=>{
            if(!!object_id){
                this.getFilesFromStore(object_id).then(r=>{
                    r.blob = new File( [ r.blob ], r.name, { type : r.type } );
                    let bb = this.fileDownloadResolveArray[object_id].slice();
                    this.fileDownloadResolveArray[object_id] = undefined;
                    if(bb.length){
                        bb.forEach(res=>res.resolve(r));
                    }
                    resolve(r)
                }).catch(()=>{
                    this.Request('links',{ object_id }).then(file=>{
                        file = JSON.parse(file);
                        this.fileDownloadResolve[file.id] = ( array ) => {
                            file.offset = Number(file.offset);
                            file.size = Number(file.size);
                            let fileReader = new FileReader();
                            fileReader.readAsArrayBuffer(new File(array, file.name, { type : file.type }));
                            fileReader.onload = () => this.addFileToStore({ blob : fileReader.result, ...file });
                            let bb = this.fileDownloadResolveArray[object_id].slice();
                            this.fileDownloadResolveArray[object_id] = undefined;
                            if(bb.length){
                                bb.forEach(res=>res.resolve({ blob : new File(array, file.name, { type : file.type }), ...file }));
                            }
                            resolve({ blob : new File(array, file.name, { type : file.type }), ...file } )
                        }
                    }).catch(e=>reject(e))
                })
            }else{
                reject('object_id cannon by empty');
            }
        })
    }

    async  digestMessage(buffer) {
        const hashBuffer = await window.crypto.subtle.digest("SHA-256", buffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    }

    async sendFile(file, { offset, id }, maxSpeed = 10) {

        // const arrayBuffer = await file.arrayBuffer();
        // this.digestMessage(arrayBuffer).then(HashHEX=>{
        //     console.log(HashHEX)
        // });
        //
        //
        // console.log(arrayBuffer);

        this.filesOffset[id] = Number(offset);
        const fileReader = new FileReader();

        const nextSlice = async (id) => {
            if(fileReader.readyState === FileReader.DONE || fileReader.readyState === FileReader.EMPTY){
                const slice = this.fileSlice(file, this.filesOffset[id], this.filesOffset[id] + this.config.webRtcConfig.SctpParameters.maxMessageSize - 12, file.type);
                await new Promise(r => setTimeout(r, maxSpeed));
                if(fileReader.readyState === FileReader.DONE || fileReader.readyState === FileReader.EMPTY){
                    fileReader.readAsArrayBuffer(slice);
                }else{
                    setTimeout(()=>nextSlice(id),30);
                }
            }else{
                setTimeout(()=>nextSlice(id),30);
            }
        };
        let _self =  this;




        fileReader.onload = (function() {
            return async function (e) {
                const buffer = e.target.result;
                if (buffer.byteLength === 0) return;
                try {
                    let allChunks = new Uint8Array(buffer.byteLength + 12);
                    const view = new DataView(new ArrayBuffer(12));
                    view.setInt32(0, id);
                    view.setInt32(4, file.size);
                    view.setInt32(8, _self.filesOffset[id]);
                    allChunks.set(new Uint8Array(buffer), 0);
                    allChunks.set(new Uint8Array(view.buffer), buffer.byteLength);
                    _self.produceData.send(allChunks);
                    _self.filesOffset[id] += buffer.byteLength;
                } catch (error) {
                    console.log('Deal with failure...', error);
                }
                if ((_self.filesOffset[id] + buffer.byteLength - 12) <= file.length) {
                    if (this.produceData.bufferedAmount < _self.config.webRtcConfig.SctpParameters.maxMessageSize / 2) {
                        nextSlice(id);
                    }
                }
            };
        })(file,_self);


        this.produceData.on('bufferedamountlow',()=>nextSlice(id));
        nextSlice(id);
        return this;
    }

    send(data = {}) {
        data = JSON.stringify(data);
        if(this.config.webRtcConfig.SctpParameters.maxMessageSize <= data.length){
            throw new Error('SctpParameters maxMessageSize = '+this.config.webRtcConfig.SctpParameters.maxMessageSize);
        }
        try{
            this.produceData.send(data);
        }catch (error) {
            throw new Error('produceData send failed');
        }
    }

    async postData(url = '', data = {}) {
        return new Promise((resolve, reject)=> {
            this.isAirplaneMode().then((airplaneModeOn) => {
                if(airplaneModeOn){
                    reject({error:{isAirplaneMode:true}});
                }else{
                    return fetch(this.serverUrl + url, { method: 'POST', headers: {'Content-Type': 'application/x-www-form-urlencoded'}, body: this.encrypt(data), })
                        .then(response => { return response.text().then(t => resolve(this.decrypt(t)));}).catch(e=>reject(e));
                }
            });
        });
    }

    //this.stream.getTracks().forEach(track => this.sendTrack(track,this.stream));
    stopTracks(){
        let Stopped = [];
        this.Producers.forEach(Producer=>{
            Stopped.push(Producer.id);
            this.Request('/stop',{ id:Producer.id })
                .then(()=>Producer.close())
                .catch(()=> { throw new Error('/stop Request failed'); });
        });
        this.Producers = [];
        this.Tracks.forEach(track=>track.stop());
        this.Tracks = [];
        return Stopped;
    }

    stopTracksCansume(){
        this.Tracks.forEach(track=>track.stop());
        this.Tracks = [];

        this.cunsumeList.forEach(cunsumer=>cunsumer.close());
        this.cunsumeList = [];

        this.cunsumeTracks.forEach(track=>track.stop());
        this.cunsumeTracks = [];
    }

    async getTrack(ProducerId){
        return new Promise((resolve,reject)=>{
            this.Request('/get/track',{ id:ProducerId ,rtpCapabilities:this.device.rtpCapabilities}).then(({ id, producerId, kind, rtpParameters})=>{
                this.Tr.consume({ id, producerId, kind, rtpParameters }).then(consumer=>{
                    this.Request('/resume',{ id:consumer.id }).then(()=>{
                        this.cunsumeTracks.push(consumer.track);
                        this.cunsumeList.push(consumer);
                        resolve(consumer.track);
                    }).catch(e=>reject(e));
                });
            }).catch(e=>reject(e));
        });
    }

    async sendTrack(track){
        return new Promise((resolve,reject)=>{
            this.Tracks.push(track);
            if(track.kind === 'video'){
                this.Tp.produce({ track, encodings: this.video_codec }).then(Producer=>{
                    this.Producers.push(Producer);
                    Producer.on('trackended', () => { console.log('trackended'); });
                    resolve(Producer);
                }).catch(e=>reject(e));
            }
            if(track.kind === 'audio'){
                this.Tp.produce({ track, codecOptions: {
                        opusStereo : true,
                        opusDtx    : true,
                        opusFec    : true,
                        opusNack   : true
                    }}).then(Producer=>{
                    this.Producers.push(Producer);
                    resolve(Producer);
                }).catch(e=>reject(e));
            }
        });
    }

    addReceivingTransport (transport,resolve,reject) {
        this.Tr = transport;
        this.Tr.on('close', () => {
            throw new Error('transport close '+this.Tr.id);
        });
        this.Tr.on('connect', async ({dtlsParameters}, callback) => {
            this.Tr.dtlsParameters=dtlsParameters;
            resolve({dtlsParameters, id:this.config.Ct.id,callback,type:'recive'});
        });
        let { id, appData, sctpStreamParameters, label, protocol } = this.config.DataConsumer;
        this.Tr.consumeData({ id, dataProducerId: appData.dataProducerId, sctpStreamParameters, label, protocol,})
            .then(consumerData => {

                let t23 = setInterval( () =>{
                    if(consumerData.readyState === 'open') { clearInterval(t23); this.dataProducerId = appData.dataProducerId; }
                }, 300);

                consumerData.on('message', (message) =>  {

                    if(typeof message !== 'string') {
                        if(message instanceof Blob){
                            return message.arrayBuffer().then(arrB=>this.messageBinDataChannel(arrB))
                        }else{
                            return this.messageBinDataChannel(message);
                        }
                    }

                    if(message.substring(0, 8)==='fragment'){
                        let crop = message.substring(message.indexOf('/')+1,message.length);
                        let len = Number(crop.substring(0,crop.indexOf('/')));
                        crop = crop.substring(crop.indexOf('/')+1,message.length);
                        let index = Number(crop.substring(0,crop.indexOf('/')));
                        crop = crop.substring(crop.indexOf('/')+1,message.length);
                        let id = crop.substring(0,crop.indexOf('/'));
                        crop = crop.substring(crop.indexOf('/')+1,message.length);
                        if(!this.fragment.hasOwnProperty(id)){
                            this.fragment[id] = {};
                            this.fragmentCounter[id] = 0;

                        }
                        this.fragment[id][index+'index']=crop.substring(0,message.length);
                        this.fragmentCounter[id]++;

                        if(this.fragment[id] && len === this.fragmentCounter[id]){
                            message = '';
                            for (let i = 0; i < len;) {
                                if(this.fragment[id][i+'index'] === undefined){
                                    throw new Error('consumerData message len');
                                }
                                message = message + this.fragment[id][i+'index'];
                                i++;
                            }
                            delete this.fragmentCounter[id];
                            delete this.fragment[id];
                        }else{
                            return ;
                        }
                    }
                    try {
                        message = JSON.parse(message);
                    }catch (error){
                        throw new Error('JSON.parse error consumerData message');
                    }

                    if(message.completedSync && message.UId === this.uuic){
                        this.event('connected');
                        this.PingTimer =  setInterval( () =>{
                            if((this.connectionOldTimer - this.connectionTimer)>=5){
                                //clearInterval(this.PingTimer);
                                if(this.Tp.connectionState !== 'connected' && this.Tr.connectionState !== 'connected'){
                                    this.event('disconnected',{ error : { ping : 'failed', timer : this.Timer } });
                                    if(this.Timer<=20000){
                                        this.Timer = this.Timer + 200;
                                    }
                                    this.connectionOldTimer = 0;
                                    this.connectionTimer = 0;
                                    this.request = {};
                                    return false;
                                }
                            }
                            this.connectionOldTimer = this.connectionOldTimer+1;
                            this.Request('/ping',{ ping:this.connectionTimer,UId:this.uuidClient }).then(e=>{
                                this.connectionTimer = e.pong;
                            }).catch(()=> {
                                throw new Error('/ping Request failed '+this.serverUrl);
                            });
                        }, this.Timer);
                        consumerData.on('close', () =>  this.event('disconnected',{ error : { consumerData : 'close' } }));
                        return false;
                    }

                    this.messageDataChannel(message);
                });
                this.consumerData = consumerData;
            })
            .catch(e => reject(e));
    }

    addSendTransport (transport,resolve,reject) {
        this.Tp = transport;
        this.Tp.on('produce', async ({ kind, rtpParameters }, callback) => {
            this.Request('/produce',{ kind, rtpParameters, id:this.config.Pt.id }).then(({id})=>{
                callback({id});
            }).catch(e=>reject(e));
        });
        this.Tp.on('connect', async ({dtlsParameters}, callback) => {
            this.Tp.dtlsParameters=dtlsParameters;
            resolve({dtlsParameters, id:this.config.Pt.id,callback,type:'send'});
        });
        this.Tp.on( 'producedata', async ( {sctpStreamParameters, label, protocol, appData}, callback ) => {
            if(sctpStreamParameters.streamId === 0){
                callback({id:this.config.id1});
            }
            if(sctpStreamParameters.streamId === 1){
                throw new Error('sctpStreamParameters.streamId = 1');
            }
        });
        this.Tp.produceData({ordered: this.config.webRtcConfig.SctpParameters.ordered,maxPacketLifeTime: this.config.webRtcConfig.SctpParameters.maxPacketLifeTime,label: 'chat',priority: 'medium',appData: {info: 'my-chat-DataProducer'},})
            .then(p => {
                this.produceData = p;
                this.produceData.bufferedAmountLowThreshold = this.config.webRtcConfig.SctpParameters.maxMessageSize / 2;
                let bb = 0;
                let t2 = setInterval( () =>{
                    bb = bb +1;
                    if(bb >=60){
                        clearInterval(t2);
                    }else{
                        if(p.readyState === 'open' && this.dataProducerId) {
                            clearInterval(t2);
                            p.send(JSON.stringify({ sync:true, dataProducerId:this.dataProducerId }));
                        }
                    }
                }, 300);
            }).catch(e => reject(e));
    }

    async refreshConnection(){

        return  Promise.all([
            this.postData('/transport/connect/refresh',{ id:this.Tp.id, dtlsParameters:this.Tp.dtlsParameters, 'authCookie': this.uuidClient, uuic:this.uuic })
                .then((param) =>{
                    let { iceParameters, error } = JSON.parse(param);
                    if(iceParameters){
                        try{
                            this.Tp.restartIce({ iceParameters });
                            return this.Tp.connectionState;
                        }catch (e) {
                            throw new Error('IceParameters Netwoks operator change');
                        }

                    }else{
                        if(error){
                            throw new Error(error);
                        }else{
                            throw new Error('IceParameters not found');
                        }
                    }
                }),
            this.postData('/transport/connect/refresh',{ id:this.Tr.id, dtlsParameters:this.Tr.dtlsParameters, 'authCookie': this.uuidClient, uuic:this.uuic })
                .then((param) =>{
                    let { iceParameters, error } = JSON.parse(param);
                    if(iceParameters){
                        try{
                            this.Tr.restartIce({ iceParameters });
                            return this.Tr.connectionState;
                        }catch (e) {
                            throw new Error('IceParameters Netwoks operator change');
                        }

                    }else{
                        if(error){
                            throw new Error(error);
                        }else{
                            throw new Error('IceParameters not found');
                        }
                    }
                })
        ])
            .then((v) => {
                setTimeout( () => {
                    if(this.Tp.connectionState === 'connected' && this.Tr.connectionState === 'connected'){
                        this.event('connected');
                    }
                }, 5000);
                return v;
            }).catch(error=>{
                if (!(error instanceof TypeError) || error.message !=='Failed to fetch') {
                    clearInterval(this.PingTimer);
                    throw new Error('IceParameters not found');
                }
            });


    }

    async addTransport(){
        return Promise.all([
            new Promise((resolve, reject)=> this.addSendTransport(this.device.createSendTransport(this.config.Pt),resolve,reject)),
            new Promise((resolve, reject)=> this.addReceivingTransport(this.device.createRecvTransport(this.config.Ct),resolve,reject))
        ])
            .then((values) => {
                this.postData('/transport/connect',{ values, 'authCookie': this.uuidClient, uuic:this.uuic })
                    .then(() => values.forEach(e=>e.callback()))
                    .catch(e => this.requestFailed(e));
            }).catch(e=>this.requestFailed(e));
    }

    requestFailed(error) {
        throw new Error(error);
    }

    connect() {

        this.uuic = this.getUuid();
        if(this.Timer<=60000){
            this.Timer = this.Timer + 200;
        }
        if(this.device === false){
            this.device = new Device();
        }

        if (!detectDevice()) {
            console.warn("no suitable handler found for current browser/device");
        }

        return this.Storage.getItem('uuid').then(uuidClient => {
            if (uuidClient === null || uuidClient === false) {
                this.uuidClient = this.getUuid();
                this.Storage
                    .setItem('uuid', this.uuidClient)
                    .catch(()=> { throw new Error('Storage'); });
            }else{
                this.uuidClient = uuidClient;
            }
            return this.postData('/config', {
                authCookie: this.uuidClient,
                uuic:this.uuic
            })
                .then(config => {

                    this.config = JSON.parse(config);

                    if(this.config.error){
                        console.log('  -> \x1b[31m '+this.config.error+'  \x1b[0m');
                        return ;
                    }

                    if(this.config.user.error){
                        console.log('  -> \x1b[31m '+this.config.user.error+'  \x1b[0m');
                        //return ;
                    }

                    this.User = this.config.user;
                    //delete this.config.user;
                    this.dataProducerId = false;
                    setTimeout( () =>{ if(this.connectionTimer===0){ this.requestFailed({ error : 'create transport or connect ctransport failed' }); }  }, 30000);
                    if(this.device.loaded){
                        this.addTransport()
                            .catch(() => { throw new Error('addTransport failed'); });
                    }else{
                        let { routerRtpCapabilities } = this.config;
                        this.device
                            .load({ routerRtpCapabilities })
                            .then(() => this.addTransport())
                            .catch(() => { throw new Error('device load error'); });
                    }
                    return this.config.clientPort;
                })
                .catch(() => { setTimeout( ()=> this.connect(), 5000); });
        });
    }
}
