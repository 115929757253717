import { createSlice } from '@reduxjs/toolkit';
export const message = createSlice({
    name: 'message',
    initialState: {
        message: {}
    },
    reducers: {
        setMessage: (state, action) => {
            state.message = {...state.message, ...action.payload};
        }
    }
});
export const { set } = message.actions;
export default message.reducer;
